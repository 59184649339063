import HomeActionTypes from './home.types';

export const artistsSyncStart = payload => ({
    type: HomeActionTypes.ARTISTS_SYNC_START,
    payload
});

export const artistsSyncFinish = payload => ({
    type: HomeActionTypes.ARTISTS_SYNC_FINISH,
    payload
});

export const brandsSyncStart = payload => ({
    type: HomeActionTypes.BRANDS_SYNC_START,
    payload
});

export const brandsSyncFinish = payload => ({
    type: HomeActionTypes.BRANDS_SYNC_FINISH,
    payload
});

export const setLocation = payload => ({
    type: HomeActionTypes.SET_LOCATION,
    payload
})

export const setIsSigninForBuyTicket = payload => ({
    type: HomeActionTypes.SET_IS_SIGNIN_FOR_BUY_TICKET,
    payload
})