import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserService from '../../services/user/user.service';
import AddToAppleWallet from "./Add_to_Apple_Wallet.svg";

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})

export class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
            smartContractId: this.props.match.params.smartContractId,
            eventId: this.props.match.params.eventId,
            eventData: null,
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const numberRegex = new RegExp("^[0-9]*$");
        var smartContractId = this.props.match.params.smartContractId;
        var eventId = this.props.match.params.eventId;
        if (smartContractId != null || eventId != null) {
            if ((!numberRegex.test(smartContractId)) || (!numberRegex.test(eventId))) {
                NotificationManager.error("", "Invalid Url.", 3000);
               // console.log("Notification")
            } else {
                this.setState({
                    smartContractId: parseInt(smartContractId),
                    eventId: parseInt(eventId),
                    isLoading: 1,
                }, () => {
                    this.getTicketInfo();
                })
            }
        }
    }

    getTicketInfo = async () => {
        try {
            const res = await UserService.getTicketDetail(this.state.smartContractId, this.state.eventId);
            if (res.error != null) {
                //console.log(res);
                if (res.error.response != null && res.error.response.data != null && res.error.response.data.message != null) {
                    NotificationManager.error("", res.error.response.data.message, 3000);
                } else if (res.error.message != null) {
                    NotificationManager.error("", "Could not get ticket details.", 3000);
                    // NotificationManager.error("", res.error.message, 3000);
                } else {
                }
                this.setState({ isLoading: 0 });
            } else {
                if (res["status"]) {
                    const data = res["data"];
                    if (data === null) {
                        NotificationManager.error("", "Invalid Ticket Id.", 3000);
                    }
                    this.setState({ eventData: res["data"], isLoading: 0 });
                } else {
                    NotificationManager.error("", "Could not get ticket details.", 3000);
                    this.setState({ isLoading: 0 });
                }
            }
        } catch (err) {
            this.setState({ isLoading: 0 });
        }
    };

    getWalletPass = () => {
        try {
            var bytes = new Uint8Array(JSON.parse(this.state.eventData.buffer));
            var blob = new Blob([bytes], { type: "application/vnd.apple.pkpass" });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Event.pkpass";
            link.click();
        } catch (err) {
            NotificationManager.error("", "Could not get Apple Wallet Pass.", 3000);
            this.setState({ isLoading: false });
        }
    };

    render() {
        return (
            <div>
                {this.state.isLoading === 1 ?
                    <>
                        <Backdrop style={{ zIndex: 100, color: '#003699' }} open>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight, width: '100%' }}>
                            <img src={require("../../assets/images/zazalogo.png")} style={{ textAlign: 'center', display: 'block', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} className="c-logo" alt="streamit" />
                        </div>
                    </>
                    :
                    <div className='buy-ticket'>
                        <div className="row" style={{ justifyContent: "center", alignItems: "center", width: "100%", margin: 0 }} id="set-spacebar">
                            {this.state.eventData ? (
                                <>
                                    <img
                                        style={{ height: "60px", textAlign: "center", margin: "30px" }}
                                        onClick={this.getWalletPass}
                                        src={AddToAppleWallet}
                                        alt="Add to Apple Wallet"
                                    />
                                    <iframe
                                        title='Ticket'
                                        style={{ width: "100%", height: "1200px", border: "none" }}
                                        srcDoc={this.state.eventData.ticketContent}
                                    ></iframe>
                                </>
                            ) : (
                                !this.state.isLoading && <p style={{ textAlign: "center" }}>Invalid Ticket Id.</p>
                            )}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ticket)