import { Switch, Route } from 'react-router-dom'
import SignUp from '../views/signUp/signup'
import SignIn from '../views/signIn/signIn'
import ForgotPassWord from '../views/forgot-password/forgot-password'
import ResetPassword from '../views/reset-password/reset-password'
import Error from '../views/Error/Error';
import PaymentStatus from '../views/payment/payment-status'
import AdminSignIn from '../views/signIn/admin-signIn'

const AuthenticationRoute = () => {
    return (
        <Switch>
            <Route path="/authentication/sign-up" component={SignUp} />
            <Route path="/authentication/sign-in" component={SignIn} />
            <Route path="/authentication/forgot-password" component={ForgotPassWord} />
            <Route path="/authentication/reset-password" component={ResetPassword} />
            <Route path="/authentication/payment-status/:orderId" component={PaymentStatus} />
            <Route path="/authentication/log-in" component={AdminSignIn} />
            <Route path="*" component={Error} />
        </Switch>
    )
}

export default AuthenticationRoute