import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { bindActionCreators } from "redux";
import validator from 'validator';
import UserService from '../../services/user/user.service';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as BLueeye } from "../../assets/images/blue-eye.svg";
import { ReactComponent as Whiteeye } from "../../assets/images/white-eye.svg";

const mapStateToProps = (state) => {
    return {
    };
}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})

export class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
            resetId: '',
            employeeId: '',
            resetKey: false,
            message: '',
            password: '',
            confirmPassword: '',
            passwordType: 'password',
            confirmPasswordType: 'password'
        };
        this.handlePassword = this.handlePassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (localStorage.getItem('employeeId') != null) {
            this.props.history.push('/');
        } else {
            this.setState({ resetId: '', employeeId: '' });
            this.validateResetKey();
        }
    }


    componentDidUpdate(prevProps, prevState) {

    }

    handlePassword(event) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        this.setState({ password: removeExtraSpace(event.target.value) });
    }

    handleConfirmPassword(event) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        this.setState({ confirmPassword: removeExtraSpace(event.target.value) });
    }

    validateResetKey = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const resetKey = queryParams.get("resetKey");

        if (resetKey !== '') {
            const payload = {
                resetKey: resetKey,
            }
            this.setState({ isLoading: 1 });
            UserService.validateResetKey(payload).then(res => {
                if (res.error != null) {
                    const errorMessage = typeof res.error.response.data === "string" || res.error.response.data instanceof String ? res.error.response.data : res.error.response.data.message;
                    NotificationManager.error(errorMessage, '', 2000);
                    this.timeoutHandle = setTimeout(() => {
                        this.setState({ isLoading: 0, resetId: '', employeeId: '', resetKey: false, message: errorMessage });
                        this.props.history.push('/authentication/sign-in');
                    }, 3000);
                } else {
                    if (res['status']) {
                        this.setState({ isLoading: 0, resetId: res['data'].resetId, employeeId: res['data'].employeeId, resetKey: true, message: res['message'] });
                    } else {
                        NotificationManager.error(res['message'], '', 2000);
                        this.timeoutHandle = setTimeout(() => {
                            this.setState({ isLoading: 0, resetId: '', employeeId: '', resetKey: false, message: res['message'] });
                            this.props.history.push('/authentication/sign-in');
                        }, 3000);
                    }
                }
            }).catch(err => {
                NotificationManager.error(err, '', 2000);
                this.timeoutHandle = setTimeout(() => {
                    this.setState({ isLoading: 0, resetId: '', employeeId: '', resetKey: false, message: err });
                    this.props.history.push('/authentication/sign-in');
                }, 3000);
            })
        } else {
            NotificationManager.error("ResetKey is not valid.", '', 2000);
            this.timeoutHandle = setTimeout(() => {
                this.setState({ resetId: '', employeeId: '', resetKey: false, message: 'ResetKey is not valid.' });
                this.props.history.push('/authentication/sign-in');
            }, 3000);
        }

    }

    resetpassword = () => {
        if (this.state.password === '') {
            NotificationManager.warning("Please Enter Password.", '', 2000);
        } else if (!validator.isStrongPassword(this.state.password)) {
            NotificationManager.warning("Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character and length between 8 to 20 characters.", '', 2000);
        } else if (this.state.confirmPassword === '') {
            NotificationManager.warning("Please Enter Confirm Password.", '', 2000);
        } else if (this.state.confirmPassword !== this.state.password) {
            NotificationManager.warning("Password's do not match.", '', 2000);
        } else if (this.state.password !== '' && this.state.confirmPassword !== '') {
            const payload = {
                password: this.state.password,
                employeeId: this.state.employeeId,
                resetId: this.state.resetId
            }
            this.setState({ isLoading: 1 });
            UserService.saveResetPassword(payload).then(res => {
                if (res.error != null) {
                    NotificationManager.error(res.error.response.data.message, '', 2000);
                    this.timeoutHandle = setTimeout(() => {
                        this.setState({ isLoading: 0, password: '', confirmPassword: '' });
                        this.props.history.push('/authentication/sign-in');
                    }, 3000);
                } else {
                    if (res['status']) {
                        NotificationManager.success("Password Reset Successfully.", '', 2000)
                        this.timeoutHandle = setTimeout(() => {
                            this.setState({ isLoading: 0 });
                            this.props.history.push('/authentication/sign-in');
                        }, 3000);

                    } else {
                        NotificationManager.error(res['message'], '', 2000);
                        this.timeoutHandle = setTimeout(() => {
                            this.setState({ isLoading: 0, password: '', confirmPassword: '' });
                            this.props.history.push('/authentication/sign-in');
                        }, 3000);
                    }
                }
            }).catch(err => {
                NotificationManager.error(err, '', 2000);
                this.timeoutHandle = setTimeout(() => {
                    this.setState({ isLoading: 0, password: '', confirmPassword: '' });
                    this.props.history.push('/authentication/sign-in');
                }, 3000);
            })
        }
    }

    showPassword = () => {
        if (this.state.passwordType === 'password') {
            this.setState({ passwordType: 'text' })
        } else {
            this.setState({ passwordType: 'password' })
        }
    }

    showConfirmPassword = () => {
        if (this.state.confirmPasswordType === 'password') {
            this.setState({ confirmPasswordType: 'text' })
        } else {
            this.setState({ confirmPasswordType: 'password' })
        }
    }


    render() {
        return (
            <section className="sign-in-page">
                {this.state.isLoading === 1 ?
                    <Backdrop style={{ zIndex: 100, color: '#003699' }} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ''}
                <Container id="container-signin">
                    {!this.state.resetKey ?

                        <Row className="justify-content-center align-items-center height-self-center">
                            <Col lg="5" md="12" className="align-self-center">
                                <div className="sign-user_card ">
                                    <div className="sign-in-page-data">
                                        <div className="sign-in-from w-100 m-auto">
                                            <h3 className="mb-3 text-center">Reset Password</h3>
                                            <p className="mb-3 text-center">{this.state.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        :
                        <Row className="justify-content-center align-items-center height-self-center m-0">
                            <div id="set-sign-in1">
                                <Col className="align-self-center set-reset-height" id="set-sign-in">
                                    <div className="sign-user_card ">
                                        <div className="sign-in-page-data">
                                            <div className="sign-in-from w-100 m-auto">
                                                <div className="sign-in-logo">
                                                    <Link to="/"> <img src={require("../../assets/images/zazalogo.png")} className="c-logo" alt="img"></img></Link>
                                                </div>
                                                <h3 className="mb-3 text-center forgot-pwd">Reset Password</h3>
                                                <p className="text-center text-body-pwd">Enter your new password to reset</p>
                                                <div className="form-bg-mobile">
                                                    <div className="form-bg-mobile1">
                                                        <Form id="set-input-width">
                                                            <Form.Group>
                                                                <div className="input-group">
                                                                    <Form.Control type={this.state.passwordType} className="form-control mb-0 sign-in-input-pwd" id="password" placeholder="New Password" value={this.state.password} onChange={this.handlePassword} required />
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text sign-pwd-i-icon" id="inputGroupPrepend3" onClick={this.showPassword} style={{ backgroundColor: '#141414', border: '1px solid #404043', color: '#fff' }}>
                                                                            {/* <i className={this.state.passwordType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i> */}
                                                                            {this.state.passwordType === 'password' ? <BLueeye /> : <Whiteeye />}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <div className="input-group">
                                                                    <Form.Control type={this.state.confirmPasswordType} className="form-control mb-0 sign-in-input-pwd" id="confirmPassword" placeholder="Confirm New Password" value={this.state.confirmPassword} onChange={this.handleConfirmPassword} required />
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text sign-pwd-i-icon" id="inputGroupPrepend3" onClick={this.showConfirmPassword} style={{ backgroundColor: '#141414', border: '1px solid #404043', color: '#fff' }}>
                                                                            {/* <i className={this.state.confirmPasswordType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i> */}
                                                                            {this.state.confirmPasswordType === 'password' ? <BLueeye /> : <Whiteeye />}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                            <div className="sign-info text-center">
                                                                <Button className={`btn ${(this.state.password === "" || !validator.isStrongPassword(this.state.password) || this.state.confirmPassword !== this.state.password) ? "disabled" : ""}`} id="sign-inbtn" onClick={this.resetpassword}>Submit</Button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    }
                </Container>
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)