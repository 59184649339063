import { Switch, Route } from "react-router-dom";
import MainLayout from "../layouts/main-layout";
import AuthenticationLayout from "../layouts/authentication-layout";
import { setLocation } from "../store/home/home.actions";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import AppConfig from "../config/app.config";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setLocation,
    },
    dispatch
  ),
});

class LayoutsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
    };
    this.props.setLocation({ location: this.props.location });
  }

  componentDidMount() {
    if (
      localStorage.getItem("adminUserName") !== AppConfig.API.ADMIN_USERNAME ||
      localStorage.getItem("adminPassword") !== AppConfig.API.ADMIN_PASSWORD
    ) {
      this.props.history.push("/authentication/log-in");
    }
  }

  componentDidUpdate() {
    if (this.state.location !== this.props.location) {
      this.props.setLocation({ location: this.props.location });
      this.setState({ location: this.props.location });
    }
  }

  render() {
    return (
      <div className="zazabgimage-opacity">
        <div
          className={`zaza-fest-bgimage ${
            this.state.path === "/lineup" ? "lineup-bg-video" : ""
          }`}
        ></div>
        <img
          src={require("../assets/images/audience-2.png")}
          className="footer-audience"
          id="footerbg-web"
        />
        <img
          src={require("../assets/images/moble-audience.png")}
          className="footer-audience"
          id="footerbg-mobile"
        />
        <Switch location={this.props.location}>
          <Route path="/authentication" component={AuthenticationLayout} />
          <Route path="/" component={MainLayout} />
        </Switch>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LayoutsRoute));
