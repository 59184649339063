import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppConfig from "../../config/app.config";
import { Link } from "react-router-dom";
import { selectBrands, selectArtists } from "../../store/home/home.selectors";
import {
  brandsSyncStart,
  artistsSyncStart,
} from "../../store/home/home.actions";
import Error from "../Error/Error";

const mapStateToProps = (state) => {
  return {
    selectBrands: selectBrands(state),
    selectArtists: selectArtists(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      brandsSyncStart,
      artistsSyncStart,
    },
    dispatch
  ),
});

export class ViewAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 2,
      dataType: "",
      dataTypeName: "",
      dataTypeId: "",
      employeeId: null,
      dataMap: [],
      checkingUpdate: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { brandsSyncStart, selectBrands, artistsSyncStart, selectArtists } =
      this.props;

    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: localStorage.getItem("employeeId"),
      loginEmployeeId: localStorage.getItem("employeeId"),
      appId: AppConfig.API.APP_ID,
    };

    const pathArray = window.location.pathname.split("/");
    if (pathArray[2] !== null && pathArray[2] !== "") {
      this.setState({
        dataType: pathArray[2],
        employeeId: localStorage.getItem("employeeId"),
      });
      if (pathArray[2] === "brands") {
        this.setState({ dataTypeName: "Brands" });
        if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
          this.setState({ dataMap: selectBrands.brandsList });
        } else {
          brandsSyncStart(payload);
        }
      } else {
        if (selectArtists.initialSynced && selectArtists.artirtsList !== null) {
          for (let i = 0; i < selectArtists.artirtsList.length; i++) {
            const rowData = selectArtists.artirtsList[i];
            if (parseInt(rowData.channelCategoryId) === parseInt(pathArray[2]))
              this.setState({
                dataMap: rowData.influencerList,
                dataTypeName: rowData.channelCategoryName,
              });
          }
        } else {
          artistsSyncStart(payload);
        }
      }
      this.interval = setInterval(() => this.setState({ isLoading: 0 }), 5000);
    } else {
      this.props.history.goBack();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectBrands, selectArtists } = this.props;
    if (this.state.dataType === "brands") {
      if (selectBrands.initialSynced) {
        if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
          this.setState({ dataMap: selectBrands.brandsList });
        }
      }
    } else {
      if (selectArtists.initialSynced) {
        if (prevProps.selectArtists.artirtsList !== selectArtists.artirtsList) {
          for (let i = 0; i < selectArtists.artirtsList.length; i++) {
            const rowData = selectArtists.artirtsList[i];
            if (
              parseInt(rowData.channelCategoryId) ===
                parseInt(this.state.dataType) &&
              rowData.influencerList !== this.state.dataMap
            ) {
              this.setState({
                dataMap: rowData.influencerList,
                dataTypeName: rowData.channelCategoryName,
              });
            }
          }
        }
      }
    }
  }

  render() {
    return (
      <section
        className="iq-main-slider p-0 iq-rtl-direction"
        style={{ position: "unset" }}
        id="iq-main-slider"
      >
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {this.state.isLoading === 2 ? (
          <>
            <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
              <CircularProgress color="inherit" />
            </Backdrop>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight,
                width: "100%",
              }}
            >
              <img
                src={require("../../assets/images/zazalogo.png")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </div>
          </>
        ) : this.state.dataMap == null || this.state.dataMap.length === 0 ? (
          <Error />
        ) : (
          <div className="main-content" id="set-header-line">
            <section id="iq-favorites">
              <Container fluid>
                <h3
                  className="text-uppercase view-all-text-movie"
                  style={{ textAlign: "center" }}
                >
                  {this.state.dataTypeName}
                </h3>
                <Row
                  sm="12"
                  className="overflow-hidden"
                  style={{ paddingLeft: "60px", paddingRight: "60px" }}
                  id="viewall-row"
                >
                  {this.state.dataMap != null &&
                    this.state.dataMap.map((item, key) => {
                      return (
                        <Col
                          lg="3"
                          sm="4"
                          md="3"
                          xl="2"
                          key={key}
                          style={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            zIndex: 1,
                          }}
                          id="view-all-movie"
                        >
                          <div className="swiper-slide">
                            <div className="block-images position-relative">
                              <div className="img-box">
                                <img
                                  src={
                                    AppConfig.API.MANU_IMAGE +
                                    item.influencerId +
                                    "&random=" +
                                    Date.now()
                                  }
                                  className="img-fluid"
                                  id="viewall-images"
                                  alt=""
                                />
                              </div>
                              {this.state.employeeId != null ? (
                                <Link
                                  target="_blank"
                                  to={{ pathname: item.influencerWebsiteUrl }}
                                >
                                  <div className="block-description block-social-info">
                                    <h6 className="iq-title">{item.influencerName} </h6>
                                  </div>
                                </Link>
                              ) : (
                                <div className="block-description block-social-info">
                                  <h6 className="iq-title">{item.influencerName} </h6>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </Container>
            </section>
          </div>
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAll);
