import { Switch, Route } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import UserProfile from '../views/profile/user-profile';
import ChangePassword from '../views/profile/change-password';
import Home from '../views/home/home'
// import BuyTicket from '../views/buy-ticket/buy-ticket'
import Ticket from '../views/ticket/ticket'
import ViewAll from '../views/view-all/view-all-movie'
import Error from '../views/Error/Error';
import PurchasePolicy from '../views/footer-page/purchase-policy'
import PrivacyPolicy from '../views/footer-page/privacy-policy'
import TermsOfUse from '../views/footer-page/terms-of-use'

const MainRoute = () => {

    return (
        <TransitionGroup>
            <CSSTransition
                // key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch>
                    <Route path="/user-profile" component={UserProfile} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/view-all/:movieType" component={ViewAll} />
                    {/* <Route path="/buy-ticket" component={BuyTicket} /> */}
                    <Route path="/ticket/:smartContractId/:eventId" component={Ticket} />
                    <Route path="/purchase-policy" component={PurchasePolicy} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-of-use" component={TermsOfUse} />
                    {/* homepage */}
                    <Route path="/" exact component={Home} />
                    <Route path="*" component={Error} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default MainRoute