//router
import LayoutsRoute from './router/layouts-route';

//scss files
import './assets/css/bootstrap.min.css'
import './assets/css/typography.css'
import './assets/css/style.css';
import './assets/css/responsive.css'
import './assets/css/dstyles.css'

import { NotificationContainer } from "react-notifications";

function App() {
  return (
    <div className="App">
      <LayoutsRoute />
      <NotificationContainer />
      {/* <div className='smokebg'>
        <img
          src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-images/images/smoke-pink.png"
          alt="smoke-pink"
          className="ag-smoke_img ag-smoke_img__left"
          width="1920"
          height="1080"
        />
        <img
          src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-images/images/smoke-blue.png"
          alt="smoke-blue"
          className="ag-smoke_img ag-smoke_img__right"
          width="1920"
          height="1080" />
      </div> */}
    </div>
  );
}

export default App;
