import { takeLeading, put, all, call } from "redux-saga/effects";
import HomeActionTypes from "./home.types";
import { artistsSyncFinish, brandsSyncFinish } from "./home.actions";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { log } from "../../helpers/core.helper";

export function* getArtistsrList({ payload }) {
  try {
    let initialSynced = false;
    let artirtsList = [];
    let message = null;
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
    };

    const res = yield UserService.getArtistsListByChannelType(payload);
    if (res.error != null) {
      initialSynced = false;
      artirtsList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        artirtsList = res["data"];
        initialSynced = true;
        message = res["message"];
      } else {
        initialSynced = false;
        artirtsList = null;
        message = res["message"];
      }
    }

    yield put(
      artistsSyncFinish({
        initialSynced,
        artirtsList,
        message,
      })
    );
  } catch (err) {
    log("home.sagas", "getArtistsrList", err);
    yield put(
      artistsSyncFinish({
        initialSynced: false,
        artirtsList: null,
        message: err,
      })
    );
  }
}

export function* getBrandsList({ payload }) {
  try {
    let initialSynced = false;
    let brandsList = [];
    let message = null;

    const res = yield UserService.getBrandsList(payload);
    if (res.error != null) {
      initialSynced = false;
      brandsList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.brandId,
              name: row.brandName,
              webUrl: row.brandWebsiteUrl,
            };
            brandsList.push(object);
          }
        }
        initialSynced = true;
        //  brandsList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        brandsList = null;
        message = res["message"];
      }
    }

    yield put(brandsSyncFinish({ initialSynced, brandsList, message }));
  } catch (err) {
    log("home.sagas", "getBrandsList", err);
    yield put(
      brandsSyncFinish({ initialSynced: false, brandsList: null, message: err })
    );
  }
}

export function* artistsSyncStart() {
  yield takeLeading(HomeActionTypes.ARTISTS_SYNC_START, getArtistsrList);
}

export function* brandsSyncStart() {
  yield takeLeading(HomeActionTypes.BRANDS_SYNC_START, getBrandsList);
}

/**
 * Initialise all local sagas
 */
export function* homeSagas() {
  yield all([call(artistsSyncStart), call(brandsSyncStart)]);
}
