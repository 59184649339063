import { createSelector } from 'reselect';

const selectData = state => state.home;

export const selectArtists = createSelector(
    [selectData],
    home => { return { 'initialSynced': home.initialSynced, 'artirtsList': home.artirtsList, 'message': home.message } }
);

export const selectBrands = createSelector(
    [selectData],
    home => { return { 'initialSynced': home.initialSynced, 'brandsList': home.brandsList, 'message': home.message } }
);

export const selectLocation = createSelector(
    [selectData],
    home => { return { 'location': home.location } }
);

export const selectIsSigninForBuyTicket = createSelector(
    [selectData],
    home => { return { 'isSigninForBuyTicket': home.isSigninForBuyTicket } }
);