import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectLoginStatus } from "../../store/signIn/signIn.selectors";
import { userLogin, userLoginFailure } from "../../store/signIn/signIn.actions";
import validator from 'validator';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as DOT } from "../../assets/images/DOT.svg";
import { ReactComponent as BLueeye } from "../../assets/images/blue-eye.svg";
import { ReactComponent as Whiteeye } from "../../assets/images/white-eye.svg";
import { selectIsSigninForBuyTicket } from '../../store/home/home.selectors';
import { setIsSigninForBuyTicket } from '../../store/home/home.actions';

const mapStateToProps = (state) => {
    return {
        selectLoginStatus: selectLoginStatus(state),
        selectIsSigninForBuyTicket: selectIsSigninForBuyTicket(state),
    };
}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            userLogin,
            userLoginFailure,
            setIsSigninForBuyTicket,
        },
        dispatch
    )
})


var isNotifiaction = 0;

export class SignIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
            username: '',
            password: '',
            passwordType: 'password'
        };
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (localStorage.getItem('employeeId') != null) {
            this.props.history.push('/');
        } else {
            this.setState({ username: '', password: '' });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectLoginStatus, userLoginFailure } = this.props;
        if (isNotifiaction === 1) {
            if (selectLoginStatus.success != null) {
                if (selectLoginStatus.success) {
                    isNotifiaction = 0;
                    NotificationManager.success("Login Successfully.", '', 2000);
                    this.timeoutHandle = setTimeout(() => {
                        this.setState({ isLoading: 0 });
                        if (this.props.selectIsSigninForBuyTicket.isSigninForBuyTicket === true) {
                            this.props.setIsSigninForBuyTicket({ isSigninForBuyTicket: false })
                            window.location.replace("https://zazamusicfestivals.com/buy-ticket");
                        } else {
                            this.props.history.push('/');
                        }
                    }, 3000);
                    userLoginFailure({ loginSuccess: false, loading: false, err: null });
                } else {
                    if (selectLoginStatus.error != null) {
                        NotificationManager.error(selectLoginStatus.error, '', 2000);
                        isNotifiaction = 0;
                        this.timeoutHandle = setTimeout(() => {
                            this.setState({ isLoading: 0 });
                        }, 3000);
                        userLoginFailure({ loginSuccess: false, loading: false, err: null });
                    }
                }
            }
        }
    }

    handleUsernameChange(event) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        this.setState({ username: removeExtraSpace(event.target.value) });
    }

    handlePasswordChange(event) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        this.setState({ password: removeExtraSpace(event.target.value) });
    }

    signIn = () => {
        const { userLogin } = this.props;
        if (this.state.username === '') {
            NotificationManager.warning("Please Enter Email.", '', 2000);
        } else if (!validator.isEmail(this.state.username)) {
            NotificationManager.warning("Please Enter Valid Email.", '', 2000);
        } else if (this.state.password === '') {
            NotificationManager.warning("Please Enter Password.", '', 2000);
        } else if (this.state.password !== '' && this.state.username !== '') {
            const payload = {
                username: this.state.username,
                password: this.state.password,
            }
            isNotifiaction = 1;
            this.setState({ isLoading: 1 });
            userLogin(payload);

        }
    }


    showPassword = () => {
        if (this.state.passwordType === 'password') {
            this.setState({ passwordType: 'text' })
        } else {
            this.setState({ passwordType: 'password' })
        }
    }

    signInValidate = () => {
        if (!validator.isEmail(this.state.username) || this.state.password === '') {
            return false;
        } else {
            return true;
        }
    }

    render() {
        return (
            <section className="sign-in-page">
                {this.state.isLoading === 1 ?
                    <Backdrop style={{ zIndex: 100, color: '#003699' }} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ''}
                <Container id="container-signin">
                    <Row className="justify-content-center align-items-center height-self-center m-0">
                        <div id="set-sign-in1">
                            <Col className="align-self-center set-sign-height" id="set-sign-in">
                                <div className="sign-user_card ">
                                    <div className="sign-in-page-data">
                                        <div className="sign-in-from w-100 m-auto">

                                            {/* <h3 className="mb-3 text-center">Sign in</h3> */}
                                            <div className="sign-in-logo">
                                                <Link to="/"> <img src={require("../../assets/images/zazalogo.png")} className="c-logo" alt="img"></img></Link>
                                            </div>
                                            <div className="form-bg-mobile">
                                                <div className="form-bg-mobile1">
                                                    <Form id="set-input-width" onSubmit={(e) => { e.preventDefault(); }}>
                                                        {/* <p className="login-acc">Log in to your account</p> */}
                                                        <Form.Group>
                                                            <Form.Control type="email" className="form-control mb-0 sign-in-input" id="email" placeholder="Email" value={this.state.username} onChange={this.handleUsernameChange} autoComplete="off" required />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <div className="input-group">
                                                                <Form.Control type={this.state.passwordType} className="form-control mb-0 sign-in-input-pwd" id="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} required />
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text sign-pwd-i-icon" id="inputGroupPrepend2" onClick={this.showPassword} style={{ backgroundColor: '#141414', border: '1px solid #404043', color: '#fff' }}>
                                                                        {/* <i className={this.state.passwordType === 'password' ? 'd-eye-off' : 'd-eye-on'}></i> */}
                                                                        {this.state.passwordType === 'password' ? <BLueeye /> : <Whiteeye />}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                        <div className="sign-in-forgot-pwd">

                                                            <div className="d-flex justify-content-center links">
                                                                <Link to="/authentication/forgot-password" className="f-link set-veuit-signin" id='set-veuit-signin'>
                                                                    Forgot password?
                                                                </Link>
                                                            </div>
                                                            <div className="sign-vector">
                                                                <DOT className="sign-vector-img1" />
                                                            </div>
                                                            <div className="d-flex justify-content-center links">
                                                                <Link to="/authentication/sign-up" className="ml-2 set-veuit-signin">Sign up for ZAZA</Link>
                                                            </div>
                                                        </div>
                                                        <div className="sign-info">
                                                            <Button type='submit' onClick={this.signIn} className={`btn ${this.signInValidate() ? "" : "disabled"}`} id="sign-inbtn">Login</Button>
                                                            {/*<div className="custom-control custom-checkbox d-inline-block">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                    <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                                </div>*/}
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)