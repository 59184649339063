import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import FsLightbox from "fslightbox-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Banner from "../../components/banner/banner.component";
import ArtistsList from "../../components/artista-list/artists-list.component";
import BrandList from "../../components/brand-list/brand-list.component";
import AppConfig from "../../config/app.config";
import ReactJWPlayer from "react-jw-player";
import { selectBrands, selectArtists } from "../../store/home/home.selectors";
import {
  brandsSyncStart,
  artistsSyncStart,
  setIsSigninForBuyTicket,
} from "../../store/home/home.actions";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
  return {
    selectBrands: selectBrands(state),
    selectArtists: selectArtists(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      brandsSyncStart,
      artistsSyncStart,
      setIsSigninForBuyTicket,
    },
    dispatch
  ),
});

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerPlay: false,
      trailerUrl: "",
      isLoading: 1,
      checkingUpdate: false,

      bannerMap: [0],
      brandsMap: [],
      artistsDataMap: [],

      isLogin: false,
      isShowModel: false,
      modelUrl: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      localStorage.getItem("adminUserName") === AppConfig.API.ADMIN_USERNAME &&
      localStorage.getItem("adminPassword") === AppConfig.API.ADMIN_PASSWORD
    ) {
      const { brandsSyncStart, selectBrands, artistsSyncStart } = this.props;
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        employeeId: localStorage.getItem("employeeId"),
        loginEmployeeId: localStorage.getItem("employeeId"),
        appId: AppConfig.API.APP_ID,
        timeZone: null,
      };
      this.props.setIsSigninForBuyTicket({ isSigninForBuyTicket: false });
      if (localStorage.getItem("employeeId") != null) {
        payload.timeZone = null;
        this.setState({ isLogin: true });
      } else {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        payload.timeZone = timeZone;
        this.setState({ isLogin: false });
      }

      if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
        this.setState({ brandsMap: selectBrands.brandsList });
      } else {
        brandsSyncStart(payload);
      }
      if (selectArtists.initialSynced && selectArtists.artirtsList !== null) {
        this.setState({ artistsDataMap: selectArtists.artirtsList });
      } else {
        artistsSyncStart(payload);
      }
      this.interval = setInterval(() => this.setState({ isLoading: 0 }), 2000);
    } else {
      this.props.history.push("/authentication/log-in");
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectBrands, selectArtists } = this.props;

    if (selectBrands.initialSynced) {
      if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
        this.setState({ brandsMap: selectBrands.brandsList });
      }
    }
    if (selectArtists.initialSynced) {
      if (prevProps.selectArtists.artirtsList !== selectArtists.artirtsList) {
        this.setState({ artistsDataMap: selectArtists.artirtsList });
      }
    }
  }

  playTrailer = (trailerUrl) => {
    this.setState({
      trailerUrl: trailerUrl,
      trailerPlay: !this.state.trailerPlay,
    });
  };

  animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  handleClose = () => {
    this.setState({ isShowModel: false, modelUrl: "" });
  };

  handleWorldEducationShow = (event) => {
    event.target.blur();
    this.setState({
      isShowModel: true,
      modelUrl:
        "https://ciright-public.s3.amazonaws.com/wei_draft_07.2+(1080p)+(1).mp4",
    });
  };

  render() {
    return (
      <div>
        {this.state.isLoading === 1 ? (
          <>
            <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
              <CircularProgress color="inherit" />
            </Backdrop>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight,
                width: "100%",
              }}
            >
              <img
                src={require("../../assets/images/zazalogo.png")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </div>
          </>
        ) : (
          <>
            <Modal
              show={this.state.isShowModel}
              dialogClassName="modal-fullscreen"
              id="edu-modal-set"
            >
              <Modal.Header
                closeButton
                onHide={this.handleClose}
                backdrop="static"
              ></Modal.Header>
              <Modal.Body id="main-screen-modal">
                <ReactJWPlayer
                  playerId="movie"
                  playerScript="https://cdn.jwplayer.com/libraries/zFFedrqG.js"
                  file={this.state.modelUrl}
                  isAutoPlay={true}
                  // aspectRatio="20:9"
                  customProps={{
                    playbackRateControls: [1, 1.25, 1.5],
                    cast: {},
                  }}
                />
              </Modal.Body>
            </Modal>
            {this.state.bannerMap != null && this.state.bannerMap.length > 0 ? (
              <section
                id="home"
                className="iq-main-slider p-0 iq-rtl-direction"
              >
                <hr className="set-gradient-border1"></hr>
                <FsLightbox
                  toggler={this.state.trailerPlay}
                  sources={[
                    <iframe
                      src={this.state.trailerUrl}
                      title=" "
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      style={{ width: "700px", height: "650px" }}
                    />,
                  ]}
                />

                <div id="prevHome" className="swiper-button swiper-button-prev">
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div id="nextHome" className="swiper-button swiper-button-next">
                  <i className="fa fa-chevron-right"></i>
                </div>

                <Swiper
                  navigation={{
                    prevEl: "#prevHome",
                    nextEl: "#nextHome",
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  onInit={() => {
                    this.animationInit();
                  }}
                  onSlideChangeTransitionStart={() => this.animationInit()}
                  loop={this.state.bannerMap.length > 1 ? true : false}
                  id="home-slider"
                  className="slider m-0 p-0"
                >
                  {this.state.bannerMap.map((item, key) => {
                    return (
                      <SwiperSlide
                        className="slide slick-bg s-bg-1"
                        as="li"
                        key={key}
                      >
                        <Banner
                          key={key}
                          originalSeriesMap={item}
                          playTrailer={this.playTrailer}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <hr className="set-gradient-border"></hr>
              </section>
            ) : (
              ""
            )}

            {this.state.artistsDataMap != null &&
            this.state.artistsDataMap.length > 0 ? (
              <>
                {this.state.artistsDataMap?.map((item, key) => {
                  if (
                    item.influencerList != null &&
                    item.influencerList.length > 0
                  ) {
                    return (
                      <ArtistsList
                        key={key}
                        resultMap={item.influencerList}
                        id={item.channelCategoryId}
                        tagName={item.channelCategoryName}
                        // viewAllUrl={"#"}
                        viewAllUrl={`/view-all/${item.channelCategoryId}`}
                      />
                    );
                  }
                })}
              </>
            ) : (
              ""
            )}

            {this.state.brandsMap?.length > 0 ? (
              <BrandList
                resultMap={this.state.brandsMap}
                id={9}
                tagName="Brands"
                viewAllUrl="/view-all/brands"
              />
            ) : (
              ""
            )}

            {/* <section
              id="parallex"
              className="d-parallax-window parallax-window"
            >
              <div className="education-block">
                <hr className="set-gradient-border"></hr>
                <div>
                  {/* <div className='education-block-img' style={{ backgroundImage: `url(${WEI})`, backgroundRepeat: 'no-repeat' }}> */}
            {/* <div className="education-block-img">
                    <video
                      autoPlay
                      loop
                      muted
                      className="education-block-img"
                      style={{ position: "absolute" }}
                    >
                      <source
                        src={"https://d1oqx62zb80kuc.cloudfront.net/10222.mp4"}
                        type="video/mp4"
                      />
                    </video>
                    <div className="h-100">
                      <Row className="align-items-center justify-content-center h-100 parallaxt-details m-0">
                        <div className="r-mb-23 col">
                          <div className="text-center d-banner-space">
                            <h1 className="slider-text text-uppercase">
                              WORLD EDUCATION INITIATIVE
                            </h1>
                            <p className="slider-text WEI-txt">
                              The best K-12 education on the planet For every
                              child on the planet For FREE!
                            </p>
                            <div
                              className="parallax-buttons"
                              id="set-donate-btn"
                            >
                              <Link
                                className="btn d-btn"
                                to="/#"
                                onClick={this.handleWorldEducationShow}
                              >
                                VIEW NOW
                              </Link>{" "}
                              <Link
                                className="btn d-btn"
                                to="/#"
                                id="donate-now"
                              >
                                DONATE NOW
                              </Link>{" "}
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <hr id="set-gradient-border"></hr>
                  </div>
                </div>
              </div>
            </section> */}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
