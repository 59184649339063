import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserService from '../../services/user/user.service';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import AppConfig from '../../config/app.config';
import user from '../../assets/images/user/user-profile.png';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
let date = new Date();
date.setDate(date.getDate() - 1);

const mapStateToProps = (state) => {
   return {
   };
}


const mapDispatchToProps = dispatch => ({
   ...bindActionCreators(
      {
      },
      dispatch
   )
})

export class UserProfile extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isLoading: 1,
         employeeId: '',
         name: '',
         email: '',
         dob: '',
         phone: '',
         password: '',
         profileImage: '',
         file: '',
         isEditInfo: 0,
         isProfileEdit: 0,
         isDeletePhoto: 0,
      };
      this.handleName = this.handleName.bind(this);
      this.handlePhone = this.handlePhone.bind(this);
      this.handleDOB = this.handleDOB.bind(this);
   }
   componentDidMount() {
      window.scrollTo(0, 0);
      if (localStorage.getItem('employeeId') != null) {
         this.getProfileData(localStorage.getItem('employeeId'));
      } else {
         this.props.history.push('/');
      }
   }

   handleName(event) {
      this.setState({ name: event.target.value });
   }

   handlePhone(event) {
      const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
      const result = /^[0-9\b]+$/;
      if (event.target.value === '' || result.test(event.target.value)) {
         this.setState({ phone: removeExtraSpace(event.target.value) });
      }
   }

   handleDOB(date) {
      this.setState({ dob: date });
   }


   handleProfileImage(e) {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
         this.setState({
            file: reader.result,
            profileImage: reader.result
         });
      }
      reader.readAsDataURL(file)
   }

   getProfileData = (employeeId) => {
      UserService.getUserProfile(employeeId).then(res => {
         if (res.error != null) {
            this.setState({ isLoading: 0 });
            NotificationManager.warning('Somthing Went Wrong', '', 2000);
         } else {
            if (res['status']) {

               this.setState({
                  isLoading: 0,
                  employeeId: employeeId,
                  name: res['data'].name,
                  email: res['data'].email,
                  dob: res['data'].birthDate !== '' ? Date.parse(res['data'].birthDate) : '',
                  phone: res['data'].phone,
                  profileImage: AppConfig.API.USER_PROFILE + employeeId + "&random=" + Date.now(),
                  file: AppConfig.API.USER_PROFILE + employeeId + "&random=" + Date.now(),
               });
            } else {
               this.setState({ isLoading: 0 });
               NotificationManager.error(res['message'], '', 2000);
            }
         }
      }).catch(err => {
         this.setState({ isLoading: 0 });
         NotificationManager.error('Somthing Went Wrong', '', 2000);
      })
   }

   editUserInfo = () => {
      this.setState({ isEditInfo: 1 });
      this.nameInput.focus();
      this.cancelUserProfile();
   }

   cancelUserInfo = () => {
      this.setState({ isEditInfo: 0 });
      this.getProfileData(this.state.employeeId);
   }

   saveUserInfo = () => {
      var pattern = new RegExp(/^[0-9\b]+$/);
      const rule = new RegExp(/^[a-zA-Z ]{2,40}$/);
      if (this.state.name === '') {
         NotificationManager.warning("Please Enter Name.", '', 2000);
      } else if (!rule.test(this.state.name)) {
         NotificationManager.warning("Name must contains at list two letter with only characters, not allow numaric value.", '', 2000);
      } else if (this.state.phone === '') {
         NotificationManager.warning("Please Enter Phone.", '', 2000);
      } else if (!pattern.test(this.state.phone)) {
         NotificationManager.warning("Please Enter Valid Phone.", '', 2000);
      } else if (this.state.phone.length !== 10) {
         NotificationManager.warning("Please Enter Valid Phone.", '', 2000);
      } else {
         const payload = {
            phone: this.state.phone,
            name: this.state.name,
            birthDate: this.state.dob !== '' && this.state.dob !== null ? format(new Date(this.state.dob), 'MM/dd/yyyy') : '',
            isDeletePhoto: this.state.isDeletePhoto
         }
         this.setState({ isLoading: 1 });
         UserService.updateUserProfile(payload, this.state.employeeId).then(res => {
            if (res.error != null) {
               this.setState({ isLoading: 0 });
               NotificationManager.error(res.error.response.data.message, '', 2000);
            } else {
               if (res['status']) {
                  localStorage.setItem('employeeName', payload.name);
                  this.setState({ isEditInfo: 0, isLoading: 0 });
                  NotificationManager.success('Data Updated Successfully.', '', 2000);
                  window.location.reload();
               } else {
                  this.setState({ isLoading: 0 });
                  NotificationManager.error(res['message'], '', 2000);
               }
            }
         }).catch(err => {
            this.setState({ isLoading: 0 });
            NotificationManager.error(err, '', 2000);
         })
      }
   }

   editUserProfile = () => {
      this.setState({ isProfileEdit: 1 });
      this.cancelUserInfo();
   }

   deleteProfile = () => {
      this.setState({ isDeletePhoto: 1, profileImage: '' });
   }

   cancelUserProfile = () => {
      this.setState({ isDeletePhoto: 0, profileImage: this.state.file, isProfileEdit: 0 });
      this.getProfileData(this.state.employeeId);
   }

   saveUserProfile = () => {
      var userProfileImage = '';
      if (this.state.profileImage !== '') {
         const imageBase64 = this.state.profileImage.split(',');
         userProfileImage = imageBase64[1];
      }
      const payload = {
         phone: this.state.phone,
         name: this.state.name,
         birthDate: this.state.dob !== '' && this.state.dob !== null ? format(new Date(this.state.dob), 'MM/dd/yyyy') : '',
         photo: userProfileImage,
         isDeletePhoto: this.state.isDeletePhoto
      }
      this.setState({ isLoading: 1 });
      UserService.updateUserProfile(payload, this.state.employeeId).then(res => {
         if (res.error != null) {
            this.setState({ isLoading: 0 });
            NotificationManager.error(res.error.response.data.message, '', 2000);
         } else {
            if (res['status']) {
               localStorage.setItem('employeeName', payload.name);
               this.setState({ isProfileEdit: 0, isLoading: 0 });
               NotificationManager.success('Profile Updated Successfully.', '', 2000);
               window.location.reload();
            } else {
               this.setState({ isLoading: 0 });
               NotificationManager.error(res['message'], '', 2000);
            }
         }
      }).catch(err => {
         this.setState({ isLoading: 0 });
         NotificationManager.error(err, '', 2000);
      })
   }


   render() {

      return (
         <section className="m-profile setting-wrapper">
            {this.state.isLoading === 1 ?
               <Backdrop style={{ zIndex: 100, color: '#003699' }} open>
                  <CircularProgress color="inherit" />
               </Backdrop>
               : ''}
            <Container>
               <h4 className=" mb-4 basic-information">Basic information</h4>
               <Row>
                  <Col lg="4" className="mb-3">

                     <div className="sign-user_card text-center">
                        <div className="upload_profile d-inline-block">
                           <img src={this.state.profileImage !== '' ? this.state.profileImage : user} className="profile-pic avatar-130 rounded-circle img-fluid" alt="user" />
                           {this.state.isProfileEdit === 1 ?
                              <label className="p-image">
                                 <input accept="image/*" type="file" onChange={(e) => this.handleProfileImage(e)} style={{ display: 'none' }} />
                                 <i className="ri-pencil-line upload-button"></i>

                              </label>
                              : ''}

                           {this.state.isProfileEdit === 1 ?
                              <label className="d-image" onClick={this.deleteProfile}>
                                 <i className="ri-delete-bin-line upload-button"></i>
                              </label>
                              : ''}
                        </div>
                        <h4 className="mb-3 mt-3">{this.state.name}</h4>

                        {this.state.isProfileEdit === 0 ?
                           <Link to="#" onClick={this.editUserProfile} className="edit-save text-primary">Edit</Link>
                           :
                           <Link to="#" onClick={this.saveUserProfile} className="edit-save text-primary">Save</Link>
                        }
                        {this.state.isProfileEdit === 0 ? '' :
                           <Link to="#" onClick={this.cancelUserProfile} className="edit-cancel text-primary">Cancel</Link>
                        }
                     </div>

                  </Col>
                  <Col lg="8">
                     <div className="sign-user_card">
                        <h5 className="mb-3 pb-3 a-border">Personal Details</h5>

                        {this.state.isEditInfo === 0 ?
                           <Link to="#" onClick={this.editUserInfo} className="edit-icon text-primary">Edit</Link>
                           :
                           <Link to="#" onClick={this.saveUserInfo} className="edit-icon text-primary">Save</Link>
                        }
                        {this.state.isEditInfo === 0 ? '' :
                           <Link to="#" onClick={this.cancelUserInfo} className="edit-cancel-info text-primary">Cancel</Link>
                        }
                        <Form className="mt-4" action="#">
                           <Row className="row align-items-center justify-content-between mb-3">
                              <Col md="12">
                                 <Form.Group className="form-group">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" maxLength="40" className="form-control mb-0" id="name" ref={(input) => { this.nameInput = input; }}
                                       placeholder="Enter Your Name" autoComplete="off" value={this.state.name} onChange={this.handleName} readOnly={this.state.isEditInfo === 0 ? true : false} />
                                 </Form.Group>
                              </Col>
                           </Row>
                           <Row className="row align-items-center justify-content-between mb-3">
                              <Col md="12">
                                 <Form.Group className="form-group">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" className="form-control mb-0" id="email"
                                       placeholder="Enter Your Email" autoComplete="off" value={this.state.email} readOnly={true} />
                                 </Form.Group>
                              </Col>
                           </Row>
                           <Row className="align-items-center justify-content-between mb-3">
                              <Col md="12">
                                 <Form.Group className="form-group">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <DatePicker selected={this.state.dob} dateFormat="MM/dd/yyyy" maxDate={date} onChange={this.handleDOB} readOnly={this.state.isEditInfo === 0 ? true : false} />
                                 </Form.Group>
                              </Col>
                           </Row>
                           <Row className="align-items-center justify-content-between">
                              <Col md="12">
                                 <Form.Group className="form-group">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" className="form-control mb-0" id="phone"
                                       placeholder="Enter Your Phone" maxLength="10" autoComplete="off" value={this.state.phone} onChange={this.handlePhone} readOnly={this.state.isEditInfo === 0 ? true : false} />
                                 </Form.Group>
                              </Col>
                           </Row>
                        </Form>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
      );
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)

