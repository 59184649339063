//const URL = "https://stage.myciright.com/Ciright";
const URL = "https://www.myciright.com/Ciright";

const AppConfig = {
    API: {
        ENDPOINT: URL + '/api',
        USER_PROFILE: URL + '/ajaxCall-photo.htm?flag=employeePhoto&compress=0&colorCode=veuit&id=',
        MANU_IMAGE: URL + '/ajaxCall-photo.htm?flag=veuitNetworkManufactLogo&compress=0&id=',

        //----- Local
        // SUBSCRIPTION_ID: 8247266,
        // VERTICAL_ID: 18,
        // APP_ID: 2063,
        // SPHERE_TYPE_URL: "zaza-live-event.htm",
        // SPHERE_TYPE_ID: 2,
        // MANUFACTURE_ID: 19926,
        //ZAZA_CHANNEL_TYPE: 140118,
        //ZAZA_EVENT_ID: 2097119,
        //ZAZA_ZIPCODE: "08401",

        //----- Live 
        SUBSCRIPTION_ID: 8263180,
        VERTICAL_ID: 2453,
        APP_ID: 2749,
        SPHERE_TYPE_URL: "zaza-live-event.htm",
        SPHERE_TYPE_ID: 2,
        MANUFACTURE_ID: 19926,
        ZAZA_CHANNEL_TYPE: 140118,
        ZAZA_EVENT_ID: 2097119,
        ZAZA_ZIPCODE: "08401",
        PAYMENT_MODE: 2,
        ZAZA_CHANNEL_TYPE_15_MAY: 149518,
        ZAZA_CHANNEL_TYPE_16_MAY: 149519,
        ADMIN_USERNAME: "admin",
        ADMIN_PASSWORD: "cirightzaza2023",
    }
}

export default AppConfig;