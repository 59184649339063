import React, { } from 'react'
import { Container, Row, Col, Dropdown, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../Card'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppConfig from '../../config/app.config';
import "react-notifications/lib/notifications.css";
import CustomToggle from '../dropdowns'
//img

import { ReactComponent as Iconwhite } from "../../assets/images/header-right.svg";
import { ReactComponent as IconBlue } from "../../assets/images/icon-blue.svg";
import { ReactComponent as Polygon } from "../../assets/images/black-polygon.svg";
import { ReactComponent as Footericon } from "../../assets/images/bottom-line.svg";
import { Drawer } from '@material-ui/core';
import { selectLocation } from '../../store/home/home.selectors';
// import { ReactComponent as ZAZALOGO } from "../../assets/images/ZaZa-New-logo.svg";

const mapStateToProps = (state) => {
    return {
        selectLocation: selectLocation(state),
    };
}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {

        },
        dispatch
    )
})



export class HeaderManu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeId: null,
            employeeName: null,
            employee: null,
            isSession: 0,
            profileImage: '',
            isProfileDropdownVisible: false,
            currentPath: null,
        };
    }

    componentDidMount() {
        if (localStorage.getItem('employeeId') != null) {
            this.setState({
                employeeId: localStorage.getItem('employeeId'),
                employeeName: localStorage.getItem('employeeName'),
                employee: JSON.parse(localStorage.getItem('employeeData')),
                isSession: 1,
                profileImage: AppConfig.API.USER_PROFILE + localStorage.getItem('employeeId') + "&random=" + Date.now(),
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectLocation.location != null && this.props.selectLocation.location.pathname !== this.state.currentPath) {
            this.setState({ currentPath: this.props.selectLocation.location.pathname });
        }
        if (localStorage.getItem('employeeId') != null) {
            if (this.state.isSession === 0) {
                this.setState({
                    isSession: 1,
                });
            }
        } else {
            if (this.state.isSession === 1) {
                this.setState({
                    isSession: 0,
                });
            }
        }
    }

    logout = () => {
        localStorage.clear();
        this.setState({ employeeId: '', isSession: 0 });
        window.location.reload(false);
    }

    render() {
        return (
            <header id="main-header">
                <div className="main-header">
                    <Container fluid id="header-container">
                        <Row id="d-header">
                            <Col sm="12" id="d-header-col">
                                <div
                                    className={`header-profile ${this.state.employeeId === null ? "small" : ""}`}
                                >
                                    {this.state.employeeId !== null &&
                                        <>
                                            <img src={this.state.profileImage} className="img-fluid avatar-40 rounded-circle" alt="user" />
                                            <p>{this.state.employeeName}</p>
                                        </>
                                    }
                                </div>

                                <div className={`center-logo ${this.state.employeeId === null ? "left-logo" : ""}`}>
                                <Link to="/">
                                    <img src={require("../../assets/images/zazalogo.png")} className="c-logo" alt="img" /> 
                                    {/* <ZAZALOGO className="zazalogo-web"  /> */}
                                </Link>
                                </div>

                                <div className="navbar-right menu-right" id='right-sidetoggle'>
                                    <Dropdown className="nav-item m-0" show={this.state.isProfileDropdownVisible} onToggle={(isOpen, event, metadata) => {
                                        if (metadata && metadata.source === "rootClose") {
                                            this.setState({ isProfileDropdownVisible: false })
                                        }
                                    }}>
                                        <Dropdown.Toggle href="#" as={CustomToggle} onClick={() => { this.setState({ isProfileDropdownVisible: !this.state.isProfileDropdownVisible }) }} variant="search-toggle position-relative">
                                            {this.state.isProfileDropdownVisible ? <IconBlue /> : <Iconwhite />}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="iq-sub-dropdown dropdown-menu dropdown-menu-right">
                                            <Card className="iq-card shadow-none m-0">
                                                <Card.Body className="iq-card-body" id="polygon-set">
                                                    <Polygon className="black-polygon" />
                                                    <div className='toggle-contents'>
                                                        <Button type="button" href={"https://zazamusicfestivals.com/buy-ticket"} variant="next action-button veuit-channel-create" style={{ color: '#fff' }}>Buy Ticket </Button>
                                                        <div className='toggle-text'>
                                                            <hr className='toggle-text-line'></hr>
                                                            <p>OR</p>
                                                            <hr className='toggle-text-line'></hr>
                                                        </div>
                                                        {this.state.employeeId != null ?
                                                            <Button type="button" onClick={this.logout} variant="next action-button veuit-channel-signin" style={{ color: '#fff' }}>{"Logout"}</Button>:
                                                            <Button type="button" href='/authentication/sign-in' variant="next action-button veuit-channel-signin" style={{ color: '#fff' }}>{"Login"}</Button>
                                                        }</div>
                                                </Card.Body>
                                            </Card>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <React.Fragment>
                    <Drawer id='header-drawer'
                        className='bottomDrawer'
                        anchor={"bottom"}
                        open={this.state.isProfileDropdownVisible}
                        onClose={() => { this.setState({ isProfileDropdownVisible: false }) }}
                    >
                        <Card className="iq-card shadow-none m-0">
                            <Card.Body className="iq-card-body" id="polygon-set">
                                <div className='footer-icon'>
                                    <Footericon />
                                </div>
                                <div id='right-sidetoggle'>
                                    <div className='toggle-contents' id='toggle-contents'>
                                        <Button type="button" href={"https://zazamusicfestivals.com/buy-ticket"} variant="next action-button veuit-channel-create" style={{ color: '#fff' }}>Buy Ticket</Button>
                                        <div className='toggle-text'>
                                            <hr className='toggle-text-line'></hr>
                                            <p>OR</p>
                                            <hr className='toggle-text-line'></hr>
                                        </div>
                                        {this.state.employeeId != null ?
                                            <Button type="button" onClick={this.logout} variant="next action-button veuit-channel-signin" style={{ color: '#fff' }}>{"Logout"}</Button> :
                                            <Button type="button" href='/authentication/sign-in' variant="next action-button veuit-channel-signin" style={{ color: '#fff' }}>{"Login"}</Button>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Drawer>
                </React.Fragment>
            </header>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderManu)
