import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationManager } from 'react-notifications';
import UserService from '../../services/user/user.service';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppConfig from '../../config/app.config';
import { ReactComponent as Failure } from "../../assets/images/Failure.svg";
import { ReactComponent as Ongoing } from "../../assets/images/Ongoing.svg";
import { ReactComponent as Somthingwrong } from "../../assets/images/somthingwrong.svg";
import { ReactComponent as Success } from "../../assets/images/Success.svg";

const mapStateToProps = (state) => {
    return {

    };
}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {

        },
        dispatch
    )
})


export class PaymentStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
            paymentStatus: 0
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.validatePayment();
    }

    validatePayment = () => {
        this.setState({ isLoading: 1 });
        const orderId = parseInt(window.location.pathname.split("/")[3]);
        const payload = {
            orderId,
            subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
            verticalId: AppConfig.API.VERTICAL_ID,
        }
        UserService.getPaymentStatus(payload).then(async res => {
            if (res.error != null) {
                this.setState({ isLoading: 0, paymentStatus: 3 });
            } else {
                if (res['status']) {
                    if (res['data'] != null && res['data'].orderStatus !== null) {
                        const orderStatus = res['data'].orderStatus;
                        const orderError = res['data'].orderError;
                        var paymentStatus = 3
                        if (orderStatus === 'PAYMENT_COMPLETE') {
                            paymentStatus = 0
                            NotificationManager.success('Transaction Success', '', 2000);
                        } if (orderStatus === 'PAYMENT_FAIL') {
                            paymentStatus = 1
                            NotificationManager.error('Transaction Failed', '', 2000);
                        } if (orderStatus === 'PAYMENT_PROCESS') {
                            paymentStatus = 2
                            NotificationManager.success('Transaction Ongoing', '', 2000);
                        }
                        this.setState({ isLoading: 0, paymentStatus, paymentError: orderError });
                    }
                } else {
                    NotificationManager.error(res['message'], '', 2000);
                    this.setState({ isLoading: 0, paymentStatus: 3 });
                }
            }
        }).catch(err => {
            NotificationManager.error(err, '', 2000);
            this.setState({ isLoading: 0, paymentStatus: 3 });
        })
    }

    render() {
        return (
            <section className="sign-in-page">
                {this.state.isLoading === 1 ?
                    <Backdrop style={{ zIndex: 100, color: '#003699' }} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Container>
                        <Row className="row justify-content-center align-items-center height-self-center">
                            <div id="set-sign-in1">
                                <Col className="align-self-center" id="set-payment-status">
                                    <div className="sign-user_card ">
                                        <div className="sign-in-page-data text-center">
                                            <div className="sign-in-from m-auto">
                                                <div className="sign-in-logo">
                                                    <Link to="/"> <img src={require("../../assets/images/zazalogo.png")} alt='ZAZA Logo'></img> </Link>
                                                </div>
                                                <div className="form-bg-mobile" id='payment-mobile'>
                                                    {
                                                        this.state.paymentStatus === 0 ?
                                                            <Success className='payment-img' />
                                                            : (this.state.paymentStatus === 1) ?
                                                                <Failure className='payment-img' />
                                                                : (this.state.paymentStatus === 2) ?
                                                                    <Ongoing className='payment-img' />
                                                                    :
                                                                    <Somthingwrong className='payment-img' />
                                                    }
                                                    {
                                                        this.state.paymentStatus === 0 ?
                                                            <h3 className='mt-3 mb-0 text-success'><strong>Success !</strong></h3>
                                                            : (this.state.paymentStatus === 1) ? <h3 className='mt-3 mb-0 text-danger'><strong>Failure</strong></h3>
                                                                : (this.state.paymentStatus === 2) ? <h3 className='mt-3 mb-0' id='ongoing-color'><strong>Ongoing</strong></h3>
                                                                    : <h3 className='mt-3 mb-0 text-danger'><strong>Oops</strong></h3>
                                                    }
                                                    <p className="text-white" id='payment-msg'>
                                                        {this.state.paymentStatus === 0
                                                            ? "Congratulations! Your Ticket is booked successful. Thank you for your booking!"
                                                            : (this.state.paymentStatus === 1) ? "Oops! Your transaction was unsuccessful. Please try again or contact our customer support for assistance."
                                                                : (this.state.paymentStatus === 2) ? "Your transaction is in progress. Please wait while we process your payment. Thank you for your patience."
                                                                    : "Something went wrong couldn't get your payment status"
                                                        }
                                                    </p>
                                                    {this.state.paymentError != null && this.state.paymentError !== "" &&
                                                        <p id='payment-error-msg'>ERROR: {this.state.paymentError}</p>
                                                    }
                                                    <div className="d-inline-block w-100">
                                                        <Link to="/" className="btn btn-primary mt-3" id='payment-back'>Back to Home</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </Container>}
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStatus)