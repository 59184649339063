import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as BLueeye } from "../../assets/images/blue-eye.svg";
import { ReactComponent as Whiteeye } from "../../assets/images/white-eye.svg";
import AppConfig from '../../config/app.config';

export class AdminSignIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
            username: '',
            password: '',
            passwordType: 'password'
        };
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (localStorage.getItem('adminUserName') === AppConfig.API.ADMIN_USERNAME
            && localStorage.getItem('adminPassword') === AppConfig.API.ADMIN_PASSWORD) {
            this.props.history.goBack();
        } else {
            this.setState({ username: '', password: '' });
        }
    }


    handleUsernameChange(event) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        this.setState({ username: removeExtraSpace(event.target.value) });
    }

    handlePasswordChange(event) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        this.setState({ password: removeExtraSpace(event.target.value) });
    }

    signIn = () => {
        if (this.state.username === '') {
            NotificationManager.warning("Please Enter Username.", '', 2000);
        } else if (this.state.password === '') {
            NotificationManager.warning("Please Enter Password.", '', 2000);
        } else if (this.state.password !== '' && this.state.username !== '') {
            if (this.state.username === AppConfig.API.ADMIN_USERNAME
                && this.state.password === AppConfig.API.ADMIN_PASSWORD) {
                localStorage.setItem('adminUserName', this.state.username);
                localStorage.setItem('adminPassword', this.state.password);
                NotificationManager.success("Login Successfully.", '', 2000);
                this.props.history.push('/');
            } else {
                NotificationManager.success("Invalid Credentials.", '', 2000);
            }
        }
    }


    showPassword = () => {
        if (this.state.passwordType === 'password') {
            this.setState({ passwordType: 'text' })
        } else {
            this.setState({ passwordType: 'password' })
        }
    }

    signInValidate = () => {
        if (this.state.username === '' || this.state.password === '') {
            return false;
        } else {
            return true;
        }
    }

    render() {
        return (
            <section className="sign-in-page">
                {this.state.isLoading === 1 ?
                    <Backdrop style={{ zIndex: 100, color: '#003699' }} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ''}
                <Container id="container-signin">
                    <Row className="justify-content-center align-items-center height-self-center m-0">
                        <div id="set-sign-in1">
                            <Col className="align-self-center set-sign-height" id="set-sign-in">
                                <div className="sign-user_card ">
                                    <div className="sign-in-page-data">
                                        <div className="sign-in-from w-100 m-auto">

                                            <div className="sign-in-logo">
                                                <Link to="#"> <img src={require("../../assets/images/zazalogo.png")} className="c-logo" alt="img"></img></Link>
                                            </div>
                                            <div className="form-bg-mobile">
                                                <div className="form-bg-mobile1">
                                                    <Form id="set-input-width" onSubmit={(e) => { e.preventDefault(); }}>
                                                        <p className="login-acc" style={{ textAlign: 'center', paddingTop: '1px' }}>Admin Sign In</p>
                                                        <Form.Group>
                                                            <Form.Control type="text" className="form-control mb-0 sign-in-input" placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} autoComplete="off" required />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <div className="input-group">
                                                                <Form.Control type={this.state.passwordType} className="form-control mb-0 sign-in-input-pwd" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} required />
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text sign-pwd-i-icon" id="inputGroupPrepend2" onClick={this.showPassword} style={{ backgroundColor: '#141414', border: '1px solid #404043', color: '#fff' }}>
                                                                        {this.state.passwordType === 'password' ? <BLueeye /> : <Whiteeye />}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                        <div className="sign-in-forgot-pwd">
                                                        </div>
                                                        <div className="sign-info">
                                                            <Button type='submit' onClick={this.signIn} className={`btn ${this.signInValidate() ? "" : "disabled"}`} id="sign-inbtn">Login</Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default AdminSignIn;