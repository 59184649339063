import { http } from '../../services/app/app.service';

const UserService = {
    /**
     * User Sign in api call old api//m1972463
     * @param {object} signInData - the signin payload
     */
    signIn: (signInData: object) => {
        return http.post('/zazafest/m2097165', signInData).then(res => res.data).catch(error => ({ error }));
    },

    /**
     * User Sign Up api call
     * @param {object} signUpData - the signup payload
     */
    signUp: (signUpData: object) => {
        return http.post('/zazafest/m2097167', signUpData).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * send reset password api call
    * @param {object} payload - the post data payload
    */
    sendResetPasswordLink: (payload: object) => {
        return http.post('/commonrestapi/m1319044', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
     * validate reset key api call
     * @param {object} payload - the post data payload
     */
    validateResetKey: (payload: object) => {
        return http.post('/commonrestapi/m1319043', payload).then(res => res.data).catch(error => ({ error }));
    },


    /**
    * save reset password api call
    * @param {object} payload - the post data payload
    */
    saveResetPassword: (payload: object) => {
        return http.post('/commonrestapi/m1319051', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * get user profile in call api
    * @param {Long} employeeId - the employeeId 
    */
    getUserProfile: (employeeId: Long) => {
        return http.get(`/commonrestapi/m1064731/${employeeId}`).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * save user profile data api call
    * @param {object} payload - the post data payload
    * @param {Long} employeeId - the employeeId
    */
    updateUserProfile: (payload: object, employeeId: Long) => {
        return http.put(`/commonrestapi/m1065385/${employeeId}`, payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
   * update password api call
   * @param {object} payload - the post data payload
   */
    updatePassword: (payload: object) => {
        return http.post('/commonrestapi/m1303853', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
   * get Artists list api call
   * @param {object} payload - the post data payload
   */
    getArtistsListByChannelType: (payload: object) => {
        return http.post('/zazafest/m2097171', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
     * get barnds list api call
     * @param {object} payload - the post data payload
     */
    getBrandsList: (payload: object) => {
        return http.post('/zazafest/m2097177', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * get Channel Category api call
    * @param {object} payload - the post data payload
    */
    getChannelCategoryList: (payload: object) => {
        return http.post('/zazafest/m2097171', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * get payment status api call
    * @param {object} payload - the post data payload
    */
    getPaymentStatus: (payload: object) => {
        return http.post('/zazafest/m2098902', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
     * update Transaction Address api call
     * @param {object} payload - the post data payload
     */
    updateTransactionAddress: (payload: object) => {
        return http.post('/veuitNetwork/m2088805', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * Get All events from master eventId
    * @param {number} eventId - the post data payload
    */
    getEventListFromMasterEventId: (eventId: number) => {
        return http.get(`/zazafest/m2097172/${eventId}`).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * Get All events from master eventId
    * @param {string} zipCode - the post data payload
    */
    getTaxByZipcode: (zipCode: string) => {
        return http.get(`/zazafest/m2097403/${zipCode}`).then(res => res.data).catch(error => ({ error }));
    },

    /**
     * Buy Event Ticket
     * @param {object} payload - the post data payload
     */
    buyEventTicket: (payload: object) => {
        return http.post('/zazafest/m2097404', payload).then(res => res.data).catch(error => ({ error }));
    },

    /**
    * Get All events from master eventId
    * @param {string} contractId - the post data payload
    * @param {string} eventId - the post data payload
    */
    getTicketDetail: (contractId: string, eventId: string) => {
        return http.get(`/zazafest/m2097725/${contractId}/${eventId}`).then(res => res.data).catch(error => ({ error }));
    },

};

export default UserService;