import React from 'react'
import { Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectLocation } from '../../store/home/home.selectors';

const mapStateToProps = (state) => {
    return {
        selectLocation: selectLocation(state)
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {

        },
        dispatch
    )
})

export class FooterManu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routeName: ""
        };
    }
    componentDidMount() {
        this.setState({ routeName: window.location.pathname })
    }
    componentDidUpdate(prevState, prevProps) {
        if (this.props.selectLocation.location !== null && this.props.selectLocation.location !== undefined && this.state.routeName !== this.props.selectLocation.location.pathname) {
            this.setState({ routeName: this.props.selectLocation.location.pathname })
        }
    }

    render() {
        return (
            <footer id="contact" className={`footer-one iq-bg-dark ${(this.state.routeName?.split("/")[1] === "podscast-details") ? "footer-podscast" : ""}`} style={{ zIndex: 1 }}>
                <div className="footer-top">
                    <Container fluid id="footer-container">
                        <Row className="footer-standard">
                            <div className='set-veuit-footer'>
                                <div className='veuit-footer-logo'>
                                    <Link to="/">
                                        <img src={require("../../assets/images/zazalogo.png")} className="c-logo" alt="img"></img>
                                    </Link>
                                </div>
                            </div>
                            <div className="widget text-left">
                                <div className="textwidget">
                                    <p>© 2023 Zaza Festivals Inc. All rights reserved.</p>
                                </div>
                            </div>
                            <div className="widget text-left">
                                <div className="menu-footer-link-1-container" id='set-list-footer'>
                                    <ul id="menu-footer-link-1" className="menu p-0">
                                        <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316 privacy-footer">
                                            <Link to="/privacy-policy"><h6 style={{ color: '#40bbfe' }} className="footer-list-text">Privacy Policy</h6></Link>
                                        </li>
                                        {/* <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316 privacy-footer">
                                            <Link to="/terms-of-use"><h6 style={{ color: '#40bbfe' }} className="footer-list-text">Terms Of Use</h6></Link>
                                        </li> */}
                                        <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                                            <Link to="/purchase-policy"><h6 style={{ color: '#40bbfe' }} className="footer-list-text">Purchase Policy</h6></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </footer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterManu)