import { takeLeading, put, all, call } from 'redux-saga/effects';
import SignInActionTypes from './signIn.types';
import {
    userLoginSuccess, userLoginFailure,
} from './signIn.actions';
import AppConfig from '../../config/app.config';
import UserService from '../../services/user/user.service';
import { log } from '../../helpers/core.helper';


/** performs user login */
export function* loginUser({ payload }) {
    try {
        const signInData = {
            username: payload.username,
            password: payload.password,
            subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
            verticalId: AppConfig.API.VERTICAL_ID,
            appId: AppConfig.API.APP_ID,
            sphereTypeUrl: AppConfig.API.SPHERE_TYPE_URL
        };
        const res = yield UserService.signIn(signInData);
        if (res.error != null) {
            yield put(userLoginFailure({ loginSuccess: false, loading: false, err: res.error.response.data.message }));
        } else {
            if (res['status']) {
                const networkRoles = res['data'].networkRoles;
                let isLogin = false;
                if (networkRoles != null) {
                    for (let i = 0; i < networkRoles.length; i++) {
                        const row = networkRoles[i];
                        if (row.sphereTypeId === 2) {
                            localStorage.setItem('employeeId', row.employeeId);
                            localStorage.setItem('employeeEmail', row.email);
                            localStorage.setItem('employeeData', JSON.stringify(row));
                            localStorage.setItem('employeeName', row.name);
                            isLogin = true;
                        }
                    }
                }
                const affiliateRoles = res['data'].affiliateRoles;
                if (affiliateRoles != null) {
                    for (let i = 0; i < affiliateRoles.length; i++) {
                        const row = affiliateRoles[i];
                        if (row.sphereTypeId === 9) {
                            localStorage.setItem('affiliateUserId', row.employeeId);
                            localStorage.setItem('affiliateUserData', JSON.stringify(row));
                        }
                    }
                }

                const subscriptions = res['data'].subscriptions;
                localStorage.setItem('isSubscriptionsNull', subscriptions != null);

                if (isLogin) {
                    yield put(userLoginSuccess({ loginSuccess: true, loading: true }));
                } else {
                    yield put(userLoginFailure({ loginSuccess: false, loading: false, err: "Invalid Credential." }));
                }
            } else {
                yield put(userLoginFailure({ loginSuccess: false, loading: false, err: res['message'] }));
            }
        }

    } catch (err) {
        log('signIn.sagas', 'loginUser', err);
        yield put(userLoginFailure({ loginSuccess: false, loading: false, err: err }));
    }
}




/** Saga for user login */
export function* userLogin() {
    yield takeLeading(
        SignInActionTypes.LOGIN_START,
        loginUser
    )
}


/** 
 * Initialise all local sagas
 */
export function* signInSagas() {
    yield all([
        call(userLogin),
    ])
}