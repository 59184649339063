import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AppConfig from "../../config/app.config";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Artists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeId: localStorage.getItem("employeeId"),
      resultObject: props.resultObject,
      id: props.id,
      image: AppConfig.API.MANU_IMAGE + props.id + "&random=" + Date.now(),
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className=" block-images position-relative">
        <div className="img-box">
          <img
            src={this.state.image}
            className="img-fluid d-influ-img"
            alt=""
          />
        </div>
        {this.state.employeeId != null ? (
          <Link
            target="_blank"
            to={{ pathname: this.state.resultObject.influencerWebsiteUrl }}
          >
            <div className="block-description block-social-info">
              <h6 className="iq-title">
                {this.state.resultObject.influencerName}{" "}
              </h6>
            </div>
          </Link>
        ) : (
          <div className="block-description block-social-info">
            <h6 className="iq-title">
              {this.state.resultObject.influencerName}{" "}
            </h6>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
