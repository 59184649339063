import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
// Partials
import HeaderManu from '../components/header/header-manu';
import FooterManu from '../components/footer/footer-manu'

// Router Component
import MainRoute from '../router/main-route'
import { selectLocation } from '../store/home/home.selectors';
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {
    selectlocation: selectLocation(state)
  };
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {

    },
    dispatch
  )
})

export class MainLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      path: null,
    };
  }

  componentDidMount() {
    if (this.props.selectlocation !== null && this.props.selectlocation.location !== null && this.props.selectlocation.location.pathname !== null) {
      this.setState({ path: this.props.selectlocation.location.pathname })
    }
    var backToTop = document.getElementById("back-to-top")
    if (backToTop !== null && backToTop !== undefined) {
      // document.getElementById("back-to-top").classList.add('animated')
      window.addEventListener('scroll', (e) => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementById("back-to-top").classList.add("show")
          // document.getElementById("back-to-top").classList.remove("fadeOut")
          // document.getElementById("back-to-top").classList.add("fadeIn")
        } else {
          document.getElementById("back-to-top").classList.remove("show")
          // document.getElementById("back-to-top").classList.remove("fadeIn")
          // document.getElementById("back-to-top").classList.add("fadeOut")
        }
      })
      // scroll body to 0px on click
      document.querySelector('#top').addEventListener('click', (e) => {
        e.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
    }
  }
  componentDidUpdate(prevState, prevProps) {
    if (this.props.selectlocation.location != null && this.props.selectlocation.location.pathname !== this.state.path) {
      this.setState({ path: this.props.selectlocation.location.pathname })
    }
  }

  render() {
    return (
      <>
        <div id="back-to-top">
          <Link className="top" to="#" id="top"> <i className="fa fa-angle-up"></i> </Link>
        </div>
        <div className="wraper">
          <HeaderManu />
          <div className="content-page" id="content-page">
            <MainRoute />
          </div>
        </div>
        <FooterManu />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)