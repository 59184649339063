import HomeActionTypes from './home.types';
// Set the reducers initial state.
const INITIAL_STATE = {
    initialSynced: false,
    message: null,
    brandsList: null,
    location: null,
    isSigninForBuyTicket: false,
    artirtsList: null
}

// Define scenarios for reducers.
const homeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.ARTISTS_SYNC_START:
            return {
                ...state,
                initialSynced: true
            };
        case HomeActionTypes.ARTISTS_SYNC_FINISH:
            return {
                ...state,
                initialSynced: action.payload.initialSynced,
                artirtsList: action.payload.artirtsList,
                message: action.payload.message,
            };
        case HomeActionTypes.BRANDS_SYNC_START:
            return {
                ...state,
                initialSynced: true
            };
        case HomeActionTypes.BRANDS_SYNC_FINISH:
            return {
                ...state,
                initialSynced: action.payload.initialSynced,
                brandsList: action.payload.brandsList,
                message: action.payload.message,
            };
        case HomeActionTypes.SET_LOCATION:
            return {
                ...state,
                location: action.payload.location,
            };
        case HomeActionTypes.SET_IS_SIGNIN_FOR_BUY_TICKET:
            return {
                ...state,
                isSigninForBuyTicket: action.payload.isSigninForBuyTicket,
            };
        default:
            return state
    }
}

export default homeReducer;