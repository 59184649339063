import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import 'react-notifications/lib/notifications.css';
//import privacyImage from '../../assets/images/privacy_policy.png'


const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})

export class PurchasePolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="iq-main-slider p-0 iq-rtl-direction" style={{ position: 'inherit' }}>
                {/* <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${privacyImage})`, height: '50vh'  }}>
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col sm="12">
                                <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                    <h2 className="title">Privacy Policy</h2>
                                </nav>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
                <main id="main" className="site-main">
                    <Container id="set-privacy-policy">
                        <Row>
                            <Col lg="12" sm="12" className='p-0'>
                                <div className="iq-privacy-policy">
                                    <div className='privacy-text-sec'>
                                        <p className="my-3">Purchase Policy</p>
                                    </div>
                                    <div className="iq-privacy-policy1" onCopy={() => { }} onPaste={() => { }} onCut={() => { }}>
                                        <div className='set-bottom-privacy'>
                                            <div className="privacy-text-set">
                                                <p className="my-3">Our goal is to make your purchasing experience seamless , efficient,
                                                    secure, and equitable.<br></br><br></br>
                                                    The following policy is designed to ensure your satisfaction and
                                                    understanding of the purchase process of the ZAZA digital festival
                                                    applications, and platform. This digital experience is the licensed
                                                    property of ZAZA Festivals, Inc. (“ZAZA”).<br></br><br></br>
                                                    This Purchase Policy applies to any purchases from ZAZA on our Site
                                                    or at any of our festivals made on or after May 1, 2023, including any
                                                    primary ticket sales. Please also review our Terms of Use, which
                                                    govern your purchase, possession, or use of any ZAZA tickets,
                                                    products, or services. If you have any questions, please contact us.</p><br></br>
                                                <p>1. Currency</p>
                                                <p>2. Payment Methods</p>
                                                <p>3. Who You Are Buying From</p>
                                                <p>4. Pricing and Availability</p>
                                                <p>5. Order Confirmation and Processing</p>
                                                <p>6. Service Fees, Order Processing Fees, Taxes, and Digital Charges</p>
                                                <p>7. Number of Tickets or “Ticket Limits”</p>
                                                <p>8. Ticket Transfer</p>
                                                <p>9. Opening Acts / Festival Acts</p>
                                                <p>10. Canceled, Postponed, Rescheduled, and Moved Events</p>
                                                <p>11. Refunds, Credits, and Exchanges</p>
                                                <p>12. Age Verification</p>
                                                <p>13. Account, Order, and Billing Information Verification</p>
                                                <p>14. Delivery Options</p>
                                                <p>15. Pricing and Other Errors</p>
                                                <p>16. Multiple Browser Windows</p>
                                                <p>17. Limitation of Liability</p>
                                                <p>18. License; Ejection and Cancelation; No Redemption Value</p>
                                                <p>19. Recording, Transmission and Exhibition</p>
                                                <p>20. You Are Subject to Search</p>
                                                <p>21. Unlawful Resale of Tickets; Promotions</p>
                                            </div>
                                            <div className="privacy-text-set">
                                                <h4 className="mb-4 contacting-us">1 .  Currency</h4>
                                                <p className="my-3">All ticket prices for events that occur in the United States are stated in
                                                    U.S. Dollars. All ticket prices for events that occur in Canada are
                                                    stated in Canadian Dollars. International live streaming purchases
                                                    shall be billed in the countries currency where event is being viewed.
                                                    Note all contract purchases are pinned to the blockchain and recorded
                                                    digitally and securely in the Ciright Core.</p>
                                                <h4 className="mb-4 contacting-us">2 .  Payment Methods</h4>
                                                <p className="my-3">We accept several methods of payment to accommodate your needs,
                                                    including (among others) American Express, Visa, MasterCard,
                                                    Discover, and, for qualifying events.</p>
                                                <h4 className="mb-4 contacting-us">3 .  Who You Are Buying From</h4>
                                                <p className="my-3">Your are buying direct from the ZAZA Festivals, Inc.</p>
                                                <h4 className="mb-4 contacting-us">4 .  Pricing and Availability</h4>
                                                <p className="my-3">All tickets are electronic and sold exclusively through the Ciright
                                                    technology platform, no other ticket purchases are acceptable.<br></br><br></br>
                                                    Tickets at times may be reserved and assigned to you. When this
                                                    occurs you will be notified electronically when payment is required for
                                                    the tickets. If payment is not received by the designated date and time
                                                    then you will forfeit your ticket reservation and be notified accordingly.
                                                    ZAZA will not be responsible for any associated claims or damages
                                                    from this reservation.<br></br><br></br>
                                                    Ticket prices may change based on market conditions.<br></br><br></br>
                                                    We are not responsible for any typographical or system errors within
                                                    the inventory listed for sale. If an error is found while processing your
                                                    order, you will be notified of the error and a substitution will be made
                                                    when available, at no additional cost, or we will issue a refund. Please
                                                    check your tickets carefully, and please contact us if you have any
                                                    questions regarding the tickets you received. We may charge
                                                    fulfillment, digital, service, or other fees for using our services. We
                                                    may change such fees at any time, including after you post your
                                                    tickets.</p>
                                                <h4 className="mb-4 contacting-us">5 .  Order Confirmation and Processing</h4>
                                                <p className="my-3">Your order is confirmed when we send you a confirmation, in the form
                                                    of a confirmation page, email, and or SMS (“Order Confirmation”).<br></br><br></br>
                                                    Once you receive the SMS message and link and or the the Email link
                                                    you must confirm your purchase. Once clicked your ticket is issued to
                                                    you for retrieval and placement on your wallet. This is to protect you
                                                    from fraudulent use of your credit card connected with your tradable
                                                    email address and mobile phone number.<br></br><br></br>
                                                    If you do not receive an Order Confirmation after submitting payment
                                                    information, or if you experience an error message or service
                                                    interruption after submitting payment information, it is your
                                                    responsibility to confirm via your account whether or not your order
                                                    has been placed. Only you may be aware of any problems that may
                                                    occur during the purchase process. We will not be responsible for
                                                    losses (monetary or otherwise) if you assume that an order was not
                                                    placed because you failed to receive confirmation.</p>
                                                <h4 className="mb-4 contacting-us">6 .  Service Fees, Order Processing Fees, Taxes, and Shipping Charges</h4>
                                                <p className="my-3">Tickets purchased on our Site are typically subject to, among other
                                                    possible fees, a per ticket service fee and a per order processing fee.
                                                    We collect tax as required by state and local laws. We may display the
                                                    tax separately or include it in the total service fee amount. In many
                                                    cases, you may need to pay a digital delivery fee. Any digital delivery
                                                    charges are calculated based on the encryption services and block
                                                    chain gas for posting your transaction to the blockchain. Please note
                                                    that the fee, as well as the order processing fee, may not reflect the
                                                    actual cost to deliver or process your order, and in some cases, these
                                                    fees may include a profit to Ciright.</p>
                                                <h4 className="mb-4 contacting-us">7 .  Number of Tickets or “Ticket Limits”</h4>
                                                <p className="my-3">When purchasing tickets from ZAZA, you are limited to a specified
                                                    number of tickets for each event (also known as a “ticket limit”). This
                                                    ticket limit is posted during the purchase process and verified with
                                                    every transaction. This policy is in effect to discourage unfair ticket
                                                    buying practices. Each account must be linked to a unique individual,
                                                    and must contain valid and verifiable information. Multiple accounts
                                                    may not be used to circumvent or exceed published ticket limits. If you
                                                    exceed or attempt to exceed the posted ticket limits, we reserve the
                                                    right to cancel, without notice, any or all orders and tickets, in addition
                                                    to prohibiting your ticket purchasing abilities. Any tickets canceled due
                                                    to violating the posted ticket limit may be refunded at face value
                                                    (excluding fees). This includes orders associated with the same name,
                                                    e-mail address, billing address, credit card number, or other
                                                    information.</p>
                                                <h4 className="mb-4 contacting-us">8 .  Ticket Transfer</h4>
                                                <p className="my-3">Ticket Transfer is a safe and easy way to transfer (send) tickets
                                                    digitally to another person. If your tickets are eligible for transfer, you
                                                    can send them to friend or family member from your online account.<br></br><br></br>
                                                    You will be required to transfer to the users email and they will be
                                                    required to have create an account on the ZAZA platform.<br></br><br></br>
                                                    Once a recipient accepts a Ticket Transfer, a new QR Code is issued
                                                    and the sender’s tickets are invalid. If a ticket has been transferred
                                                    multiple times, only the ticket from the last transfer will be valid for
                                                    event entry. All previous ticket QR code will be invalidated.<br></br><br></br>
                                                    The sender can modify or cancel a Ticket Transfer before the recipient
                                                    accepts the transfer, not after. Please note that we may cancel
                                                    transferred tickets, at any time, if we determine that they were obtained
                                                    fraudulently or otherwise in violation of our policies; therefore, it is
                                                    important that you know and trust the individual transferring tickets to
                                                    you before accepting them. All merchandise, VIP package elements,
                                                    fan club memberships, and ticket insurance originally purchased or
                                                    included with the ticket are not transferable. Ticket insurance will not
                                                    apply to transferred tickets.<br></br><br></br>
                                                    In some cases ticket may not be transferable and may only be
                                                    intended for the fan that purchased the ticket. This is based on fan
                                                    selection and digital exhaust values from listening to a specific artist
                                                    for a specific period of time which earned the fan the preferred seating
                                                    position.<br></br><br></br>
                                                    If an event is canceled, postponed, rescheduled or moved, and
                                                    refunds are approved by ZAZA, only the original purchaser—i.e., the
                                                    person who purchased the tickets at issue directly from ZAZA—will be
                                                    eligible for any available refunds or credits.<br></br><br></br>
                                                    For canceled events, the original purchaser will receive any available
                                                    refund (or may take advantage of an optional credit offer, if available),
                                                    and no additional action is needed from the recipient of the transferred
                                                    tickets.<br></br><br></br>
                                                    For postponed, rescheduled, or moved events, recipients of the
                                                    transferred tickets will need to transfer the tickets back to the original
                                                    purchaser in order for the original purchaser to take advantage of any
                                                    available refund and/or credit options. Once the tickets have been
                                                    transferred back to the original purchaser, the original purchaser will
                                                    need to contact Fan Support to complete their request.<br></br><br></br>
                                                    Please see the Canceled, Postponed, Rescheduled and Moved
                                                    Events section, below, for more information on when refunds and/or
                                                    credits may be available.<br></br><br></br>
                                                    Please note that recipients of any transferred tickets are not eligible for
                                                    any available refunds or credits, because the transfer recipient did not
                                                    purchase tickets directly from our Site. If you purchased a resale ticket
                                                    from some other source (i.e., not through our Site), and that ticket was
                                                    then transferred to you, you will need to reach out to your original point
                                                    of purchase for any refund and/or credit options, if available.</p>
                                                <h4 className="mb-4 contacting-us">9 .  Opening Acts / Festival Acts</h4>
                                                <p className="my-3">Opening acts, guests, or undercards (each an “Opening Act”) may
                                                    sometimes tour with headlining performers. We are not always made
                                                    aware of Opening Acts or the length of their performances. Opening
                                                    Acts, as well as festival performers, are subject to change or
                                                    cancelation at any time without notice. No refund will be owed if an
                                                    Opening Act or festival performer is changed or canceled.</p>
                                                <h4 className="mb-4 contacting-us">10 .  Canceled, Postponed, Rescheduled, and Moved Events</h4>
                                                <p className="my-3">Occasionally, events are canceled, postponed, rescheduled to a
                                                    different date or materially different time, or moved to a different
                                                    venue:<br></br><br></br>
                                                    If the event is canceled: no action is required to obtain a refund; we
                                                    will issue a refund to the original method of payment used at time of
                                                    purchase, once funds are received from the ZAZA. In some cases, the
                                                    ZAZA may also give you the option to choose either a credit or a
                                                    refund; if so, we will send you a notification explaining your options,
                                                    and how to submit a request for a credit.<br></br><br></br>
                                                    If the event is postponed, rescheduled, or moved: your ticket(s)
                                                    (including any additional add-ons or upgrades, such as parking) are
                                                    still valid, and no further action is required. ZAZA will credit your ticket
                                                    purchase for any future ZAZA event if you are unable to attend the
                                                    rescheduled event.<br></br><br></br>
                                                    If your event is canceled, postponed, rescheduled, or moved, we will
                                                    attempt to contact you via the email address associated with your
                                                    account. For exact instructions on any particular canceled,
                                                    postponed, rescheduled, or moved event, please check the event
                                                    information online and in your account (which will include the most
                                                    current information on the status of the event), or contact us.<br></br><br></br>
                                                    We will not be liable for travel or any other expenses that you or
                                                    anyone else incurs in connection with a canceled, postponed,
                                                    rescheduled, or moved event.</p>
                                                <h4 className="mb-4 contacting-us">11 .  Refunds, Credits, and Exchanges</h4>
                                                <p className="my-3">All sales are final and refunds are only allowed in limited
                                                    circumstances, as explained in this section and the Canceled,
                                                    Postponed, Rescheduled and Moved Events section above. Before
                                                    purchasing tickets, carefully review your event and seat selection.<br></br><br></br>
                                                    Policies set forth by ZAZA prohibit issuing exchanges or refunds after
                                                    a ticket has been purchased, or for lost, stolen, damaged, or destroyed
                                                    tickets. Tickets therefore cannot be replaced if they are lost, stolen, or
                                                    damaged. If you get hacked it is your responsibility to secure your
                                                    digital tickets. We will make every attempt to trace and track stolen
                                                    ticket use and share information with law enforcement.<br></br><br></br>
                                                    If a refund is issued, it will be processed to the original method of
                                                    payment used at time of purchase. We cannot issue a refund to a
                                                    different credit or debit card (this includes refund requests made
                                                    through the self-service option). If your credit card or debit card
                                                    number has changed, but is for the same account (e.g., a new card
                                                    has been issued for the same account), the refund will be processed
                                                    to that account.<br></br><br></br>
                                                    For any ticket insurance refunds, please contact the insurance
                                                    provider.<br></br><br></br>
                                                    Please note that seating maps are subject to change at any time, and
                                                    refunds are not allowed if the seating map is updated, if additional
                                                    seats / rows are added, or if seats change as a result of a venue
                                                    change after purchase.<br></br><br></br>
                                                    We may occasionally offer tickets at a discount after the original on￾sale date, and will not refund the difference between the original price
                                                    and the sale price. In addition, there are no refunds, returns, or
                                                    exchanges for digital downloads or hotel / festival packages.
                                                   <br></br><br></br> Credits. ZAZA may provide the option to request a credit for canceled,
                                                    postponed, rescheduled, or moved events; if so, we will send you a
                                                    notification, and you can submit a request for credit in your account.
                                                    Credits are provided by ZAZA. Credits may be applied toward the
                                                    purchase of eligible event tickets and ancillary purchases (such as
                                                    parking) at the same venue (or, in some cases, at one of a number of
                                                    venues designated by ZAZA) during the timeframe specified by the
                                                    ZAZA Credits cannot be used for resale tickets, third-party tickets,
                                                    ticket insurance, or gift cards. ZAZA will determine which future
                                                    events, venues, and ancillary purchases are eligible, as well as the
                                                    amount of the credit (including any potential promotional amount). The
                                                    amount of the credit will always include at least 100% of the price of
                                                    the original ticket(s), plus fees and taxes. The amount of the credit will
                                                    not include any additional add-ons or upgrades included in your
                                                    original purchase (such as parking), which will be refunded to your
                                                    original method of payment used at time of purchase. Once you’ve
                                                    requested to receive credit and we’ve verified your order is eligible for
                                                    credit, we’ll send you a credit code which you can use for eligible
                                                    purchases, along with instructions for redeeming that credit. Please
                                                    note that credits typically have an expiration date, and must be used
                                                    before that date. The email that you received with your credit code will
                                                    contain the expiration date for your credit; you can also find the
                                                    expiration date in your online account, under “Gift Cards & Codes.”
                                                    Credits are non-transferable, may not be sold, are not redeemable for
                                                    cash, and may not be combined with other promotions. Credits may
                                                    not be applied to previously placed orders and may not be redeemed
                                                    to purchase tickets for the same event as the tickets in your original
                                                    purchase for which you accepted the credit.<br></br><br></br>
                                                    Please note that, if ZAZA offers you a choice of either a refund or a
                                                    credit, you cannot get a partial refund; whatever selection you make
                                                    (i.e., refund or credit) will apply to your entire order, and cannot be split
                                                    between refund and credit. Any and all requests for a refund or credit
                                                    are final and cannot be changed once initiated.<br></br><br></br>
                                                    For more information on refunds or credits for events that were
                                                    canceled, postponed, rescheduled, or moved due to the COVID-19
                                                    pandemic, please see the Terms Regarding Certain Canceled,
                                                    Rescheduled and Postponed Events (COVID-19).<br></br><br></br>
                                                    Merchandise. All merchandise purchases are final, and in no event will
                                                    there be refunds, exchanges, or credits for merchandise purchases.
                                                    Chargebacks and Other Refund Prohibitions. You agree that you will
                                                    not attempt to evade, avoid, or circumvent any refund prohibitions in
                                                    any manner with regard to tickets you purchased. Without limiting the
                                                    generality of the foregoing, you will not contact us to seek a refund or
                                                    exchange under any circumstances from Ciright. We are prohibited
                                                    from providing any such refund on behalf of ZAZA, and you will not
                                                    dispute or otherwise seek a “chargeback” from the company whose
                                                    credit card or other method of payment you used to purchase tickets
                                                    from the Site. Should you do so, your tickets are subject to immediate
                                                    cancelation, and we may, in our sole discretion, refuse to honor
                                                    pending and future ticket purchases made from all credit card
                                                    accounts or online accounts on which such chargebacks have been
                                                    made, and may prohibit future purchases from all persons in whose
                                                    name the credit card accounts exist, and from any person who
                                                    accesses any associated online account or credit card or who
                                                    otherwise breaches this provision from using the Site.
                                                    Please order tickets only after you are certain you want them.</p>
                                                <h4 className="mb-4 contacting-us">12 .  Age Verification</h4>
                                                <p className="my-3">If the event is selling alcohol you will be required to provide formal
                                                    identification to purchase. This identification can be provided ahead of
                                                    time by scanning your drivers license and supplying camera access to
                                                    the ZAZA application to automate your identification verification.
                                                    Otherwise you will be required to provide Identification at the venue
                                                    and obtain the arm badge detailing if you are of the legal age.</p>
                                                <h4 className="mb-4 contacting-us">13 .  Account, Order, and Billing Information Verification</h4>
                                                <p className="my-3">All information on accounts and orders must be valid and are subject
                                                    to verification. Orders are subject to credit card approval, and are
                                                    processed only after the billing address associated with your credit
                                                    card and other billing information have been verified. Orders that are
                                                    placed, or attempted to be placed, using an account with any
                                                    information that is found to be false, misleading, incorrect, or
                                                    incomplete, or that cannot be verified as belonging to the account
                                                    holder—such as name, address, email address, phone number, IP
                                                    address, or other account or billing information—are subject to
                                                    cancelation, at any time. Furthermore, if your order is canceled for any
                                                    of the foregoing reasons, we may sell your tickets to another customer
                                                    without further notice.</p>
                                                <h4 className="mb-4 contacting-us">14 .  Delivery</h4>
                                                <p className="my-3">All tickets are only delivered via a secure digital process.
                                                    There are no physical tickets.</p>
                                                <h4 className="mb-4 contacting-us">15 .  Pricing and Other Errors</h4>
                                                <p className="my-3">If the amount you pay for a ticket is incorrect (regardless of whether it
                                                    is incorrect because of an error in a price posted on the Site or
                                                    otherwise communicated to you), if you are able to order a ticket
                                                    before its scheduled on-sale or presale date, or if you are able to order
                                                    a ticket that was not supposed to have been released for sale, then we
                                                    will have the right to cancel that ticket (or the order for that ticket) and
                                                    refund to you the amount that you paid. This will apply regardless of
                                                    whether the error occurred because of human error or a transactional
                                                    malfunction of the Site. We will not be liable for travel or any other
                                                    expenses that you, or anyone else, incurs in connection with errors of
                                                    this nature. If a refund is processed in error, or a refund exceeds the
                                                    original amount paid, we reserve the right to recharge the original
                                                    method of payment used at time of purchase.</p>
                                                <h4 className="mb-4 contacting-us">16 .  Multiple Browser Windows</h4>
                                                <p className="my-3">When ordering tickets online with us, please ensure you are looking
                                                    for tickets and placing an order using only one browser window.
                                                    Looking up tickets using multiple browser windows could result in
                                                    losing your tickets, errors occurring during the purchase process, or
                                                    timer expiration.</p>
                                                <h4 className="mb-4 contacting-us">17 .  Limitation of Liability</h4>
                                                <p className="my-3">Music festivals by nature have a high energy level and high risk for
                                                    violence. Stay alert at all times before, during, and after each
                                                    performance. If violence is identified or experienced, immediately ask
                                                    an usher for directions to a medical station and or a safe place. You
                                                    voluntarily assume all risks and danger incidental to the event for
                                                    which the ticket is issued, whether occurring before, during or after the
                                                    event, and you waive any claims for personal injury or death against
                                                    us, management, facilities, artists, other participants, and all of our
                                                    respective parents, affiliated entities, agents, officers, directors,
                                                    owners, and employees on behalf of yourself and any accompanying
                                                    minor. You bear all risks of inclement weather. Event date and time are
                                                    subject to change. Please see the Limitation of Liability section in
                                                    the Terms of Use for additional limits on our liability.<br></br><br></br>
                                                    You assume all risks, hazards, and dangers arising from or relating in
                                                    any way to the risk of contracting a communicable disease or illness—
                                                    including, without limitation, exposure to COVID-19 or any other
                                                    bacteria, virus, or other pathogen capable of causing a communicable
                                                    disease or illness, whether that exposure occurs before, during, or
                                                    after the event, and regardless of how caused or contracted—and you
                                                    hereby waive any and all claims and potential claims against Ciright
                                                    and ZAZA—relating to such risks, hazards, and dangers.</p>
                                                <h4 className="mb-4 contacting-us">18 .  License; Ejection and Cancelation; No Redemption Value</h4>
                                                <p className="my-3">You agree to comply with all of ZAZA’s applicable rules, policies,
                                                    terms, and conditions, ZAZA reserves the right, without refund of any
                                                    amount paid, to refuse admission to, or eject, any person whose
                                                    conduct management deems disorderly, who uses vulgar or abusive
                                                    language, or who fails to comply with ZAZA Event Rules. Breach of
                                                    terms or rules will terminate your license to attend the event without
                                                    refund. A ticket is a revocable license and admission may be refused.
                                                    A ticket is not redeemable for cash.</p>
                                                <h4 className="mb-4 contacting-us">19 .  Recording, Transmission and Exhibition</h4>
                                                <p className="my-3">You agree that the event for which you purchase tickets is a public
                                                    event, that your appearance and actions inside and outside the venue
                                                    where the event occurs are public in nature, and that you have no
                                                    expectation of privacy with regard to your actions or conduct at the
                                                    event. You grant permission to ZAZA, our partners, licensees and
                                                    assigns, including but not limited to our brand and media partners, to
                                                    utilize your name, image, likeness, acts, poses, plays, appearance,
                                                    movements, and statements in any live or recorded audio, video, or
                                                    photographic display or other transmission, exhibition, publication or
                                                    reproduction made of, or at, the event (regardless of whether before,
                                                    during or after play or performance) for any purpose, in any manner, in
                                                    any medium or context now known or hereafter developed, without
                                                    further authorization from, or compensation to, you or anyone acting
                                                    on your behalf. In the case of virtual events, you may view the virtual
                                                    event solely for your own personal purpose. You may not record, copy,
                                                    publicly exhibit, transmit, or distribute any virtual event through any
                                                    means, resell views of any virtual event, or allow others to log into your
                                                    account for the purpose of watching a virtual event.</p>
                                                <h4 className="mb-4 contacting-us">20 .  You Are Subject to Search</h4>
                                                <p className="my-3">You and your belongings may be searched on entry to the event. You
                                                    consent to such searches and waive any related claims that may arise.
                                                    If you elect not to consent to such searches, you may be denied entry
                                                    to the event without refund or other compensation. Under certain
                                                    facility rules, certain items may not be brought into the premises,
                                                    including without limitation: firearms, alcohol, drugs, controlled
                                                    substances, cameras, recording devices, laser pointers, strobe lights,
                                                    irritants (e.g., artificial noisemakers), bundles and containers.</p>
                                                <h4 className="mb-4 contacting-us">21 .  Unlawful Resale of Tickets; Promotions</h4>
                                                <p className="my-3">Unlawful resale (or attempted unlawful resale) of tickets, including but
                                                    not limited to counterfeit or copy of tickets, is grounds for seizure and
                                                    cancelation without compensation. Certain maximum resale premiums
                                                    and restrictions may apply in some states, and we cannot be
                                                    responsible for providing legal advice regarding resale or pricing of
                                                    tickets. You are responsible for complying with all applicable ticket
                                                    resale laws. In addition, we reserve the right to restrict or deny ticket
                                                    purchasing privileges to anyone that we determine to be, or to have
                                                    been, in violation of our policies. Because we do not guarantee the
                                                    authenticity of tickets purchased from any non-authorized third party
                                                    reseller (such as brokers or individuals), we recommend that you
                                                    purchase tickets directly from ZAZA.</p>
                                                <p className="my-3">If you have general questions about your account or how to contact customer service for assistance, please visit our online help center at help.ZAZA.com. For questions specifically about this Privacy Statement, or our use of your personal information, cookies or similar technologies, please contact our Data Protection Officer/Privacy Office by email at privacy@ZAZA.com.</p>
                                                <p className="my-3">The data controller of your personal information is ZAZA, Inc. Please note that if you contact us to assist you, for your safety and ours we may need to authenticate your identity before fulfilling your request.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePolicy)